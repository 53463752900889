import { useQuery } from '@apollo/client';
import {
  CheckOutlined,
  ExtensionOutlined,
  InfoOutlined,
  LinkedIn,
  OpenInNew,
  Refresh,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Card,
  Container,
  Divider,
  Snackbar,
  Stack,
  Typography,
  Zoom,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TypeBrand, UserRole } from '../../__generated__/graphql';
import Avatar from '../../components/Avatar/Avatar';
import LinkedinPageSelector from '../../components/SocialMediaAccounts.tsx/LinkedinPageSelector';
import { AuthContext } from '../../context/AuthContext';
import { BrandContext } from '../../context/BrandContext';
import { GET_PUBLIC_BRAND } from '../../graphql/queries';
import { formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';

const ConnectLinkedin = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const { brandId } = useParams();
  const [linkCopied, setLinkCopied] = useState(false);

  const { data, loading: loadingGetBrand } = useQuery(GET_PUBLIC_BRAND, {
    variables: { brandId: brandId! },
    skip: !brandId,
  });

  const brand = data?.getPublicBrand;

  const onCloseSnackbar = () => {
    setErrorMsg('');
  };

  const {
    handleBrandSelected,
    handleRenewAccess,
    handleConnectNewAccount,
    loading: loadingBrandContext,
    openModalCompany,
    setOpenModalCompany,
    handleSaveLinkedinCompanyCredentials,
    setLinkedinPageSelectorOpen,
    pages,
  } = useContext(BrandContext);
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const loading = loadingBrandContext || loadingGetBrand;
  const showInfo =
    user &&
    (user.role.includes(UserRole.BrandManager) ||
      user?.role.includes(UserRole.LimitedBrandManager));

  return (
    <Container
      maxWidth="sm"
      sx={{
        marginTop: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography
        variant="h1"
        fontSize={{ xs: 24, sm: 30 }}
        fontWeight={600}
        textAlign="center"
      >
        {t('Connect your LinkedIn to your Brand')}
      </Typography>
      {showInfo && (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            padding: 2,
            alignItems: 'center',
            borderRadius: 2,
            width: '100%',
            boxShadow: '0px 0px 3.6700000762939453px 0px #0000001A',
          }}
        >
          <InfoOutlined />
          <Typography fontSize={14}>
            {t(
              'Share this page with the LinkedIn account owner to connect it with their brand.',
            )}
          </Typography>
          <Button
            variant="outlined"
            sx={{ whiteSpace: 'nowrap', px: 3 }}
            endIcon={linkCopied ? <CheckOutlined /> : <></>}
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setLinkCopied(true);
            }}
          >
            {linkCopied ? t('Link copied') : t('Copy link')}
          </Button>
        </Card>
      )}
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: 4,
          alignItems: 'center',
          borderRadius: 2,
          width: '100%',
          boxShadow: '0px 0px 3.6700000762939453px 0px #0000001A',
        }}
      >
        <Avatar
          avatar={brand?.account?.avatar || undefined}
          size={80}
          name={brand?.name || ''}
          backgroundColor={brand?.backgroundColor || ''}
        />

        <Stack justifyContent={'center'} gap={5}>
          <Stack flexDirection="column" gap={1}>
            <Typography
              fontWeight={700}
              fontSize={{ xs: 20, sm: 26 }}
              justifyContent="center"
              display="flex"
              width="100%"
            >
              {brand?.name}
            </Typography>
            {brand?.account?.name ? (
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                divider={<Divider orientation="vertical" flexItem />}
                gap={{ xs: 0, sm: 1 }}
              >
                <Stack
                  direction="row"
                  sx={{
                    color: '#0A66C2',
                    alignItems: 'center',
                  }}
                  justifyContent={'center'}
                >
                  <LinkedIn height="15px" width="15px" />
                  <Typography
                    fontWeight={700}
                    fontSize="13px"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {brand!.account?.name}
                  </Typography>
                </Stack>
                <Typography
                  variant="subtitle2"
                  justifyContent="center"
                  display="flex"
                  width="100%"
                >{`${t('Access expires on')} ${formatDate(
                  new Date(brand!.account?.exp),
                )}`}</Typography>
              </Stack>
            ) : (
              <Typography display="flex" width="100%" textAlign={'center'}>
                {t('Your LinkedIn Account is not connected')}
              </Typography>
            )}
          </Stack>

          {brand?.account && brand?.account?.type && (
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={5}
            >
              <LoadingButton
                startIcon={<Refresh />}
                loading={loading}
                variant="outlined"
                onClick={() => {
                  handleBrandSelected(brand);
                  if (brand?.account?.type) {
                    handleRenewAccess(brand.account.type, window.location.pathname);
                  }
                }}
                fullWidth
              >
                {t('Renew access')}
              </LoadingButton>
            </Stack>
          )}

          {brand && !brand?.account?.name && (
            <Stack flexDirection="row" justifyContent="center" marginTop={1}>
              <LoadingButton
                loading={loading}
                onClick={() => {
                  handleBrandSelected(brand);
                  handleConnectNewAccount(
                    brand!.typeBrand as TypeBrand,
                    window.location.pathname,
                  );
                }}
                variant="contained"
                fullWidth
                startIcon={<LinkedIn />}
              >
                {t('Connect linkedin account')}
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Card>
      <Card
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 1,
          padding: 2,
          alignItems: 'center',
          borderRadius: 2,
          width: '100%',
          boxShadow: '0px 0px 3.6700000762939453px 0px #0000001A',
        }}
      >
        <Stack direction={'row'} sx={{ alignItems: 'center' }} gap={1}>
          <ExtensionOutlined sx={{ color: 'primary.main' }} />
          <Typography fontSize={14}>
            {t(
              'Make sure to install the chrome extension to get analytics of your LinkedIn account',
            )}
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          sx={{ whiteSpace: 'nowrap', px: 4 }}
          endIcon={<OpenInNew sx={{ height: '15px', width: '15px' }} />}
          onClick={() => {
            window.location.href =
              'https://chromewebstore.google.com/detail/magnett%C3%BC-for-linkedin/gbhgeemkcacibokmfjoimkgohejgehap';
          }}
        >
          {t('Get extension')}
        </Button>
      </Card>
      <Snackbar
        open={!!errorMsg}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        TransitionComponent={Zoom}
        onClose={onCloseSnackbar}
        data-cy="error-snackbar"
      >
        <Alert severity="error" sx={{ width: '100%' }} onClose={onCloseSnackbar}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <LinkedinPageSelector
        open={openModalCompany}
        onClose={() => setOpenModalCompany(false)}
        onSubmit={(page) => {
          handleSaveLinkedinCompanyCredentials(page);
          setLinkedinPageSelectorOpen(null);
          setOpenModalCompany(false);
        }}
        pages={pages}
      />
    </Container>
  );
};

export default ConnectLinkedin;
