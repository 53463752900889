import { MenuItem, Select } from '@mui/material';
import React, { Dispatch, FC } from 'react';
import { OrderByDirection, OrderByField } from '../../__generated__/graphql';
import { OrderBy } from '../../types';
import { useTranslation } from 'react-i18next';

interface Props {
  orderBy: {
    field: string;
    direction: string;
  };
  setOrderBy: Dispatch<React.SetStateAction<OrderBy>>;
}

const SelectOrderPost: FC<Props> = ({ orderBy, setOrderBy }) => {
  const { t } = useTranslation();

  return (
    <div>
      {' '}
      <Select
        value={`${orderBy.field} ${orderBy.direction}`}
        sx={{ width: '250px', marginLeft: 'auto', borderRadius: '8px', height: '40px' }}
      >
        <MenuItem
          value={`${OrderByField.CreatedOn} ${OrderByDirection.Desc}`}
          onClick={() =>
            setOrderBy({
              field: OrderByField.CreatedOn,
              direction: OrderByDirection.Desc,
            })
          }
        >
          {t('Creation date (newest to oldest)')}
        </MenuItem>
        <MenuItem
          value={`${OrderByField.CreatedOn} ${OrderByDirection.Asc}`}
          onClick={() =>
            setOrderBy({
              field: OrderByField.CreatedOn,
              direction: OrderByDirection.Asc,
            })
          }
        >
          {t('Creation date (oldest to newest)')}
        </MenuItem>
        <MenuItem
          value={`${OrderByField.PostedAt} ${OrderByDirection.Desc}`}
          onClick={() =>
            setOrderBy({
              field: OrderByField.PostedAt,
              direction: OrderByDirection.Desc,
            })
          }
        >
          {t('Publication date (descending)')}
        </MenuItem>
        <MenuItem
          value={`${OrderByField.PostedAt} ${OrderByDirection.Asc}`}
          onClick={() =>
            setOrderBy({
              field: OrderByField.PostedAt,
              direction: OrderByDirection.Asc,
            })
          }
        >
          {t('Publication date (ascending)')}
        </MenuItem>
      </Select>
    </div>
  );
};

export default SelectOrderPost;

//THIS COULD BE A SOLUTION FOR THE QUICKFILTER COMPONENT BUT WE ARE WAITING FOR TATIANA TO CONFIRM THE DESIGN AND THE APPROVAL OF SERGIO

// import React, { useState, useContext } from 'react';
// import {
//   Box,
//   Button,
//   Menu,
//   Typography,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { OrderByDirection, OrderByField, UserRole } from '../../__generated__/graphql';
// import { OrderBy } from '../../types';
// import { useTranslation } from 'react-i18next';
// import FlashOnIcon from '@mui/icons-material/FlashOn';
// import ArticleIcon from '@mui/icons-material/Article';
// import { AuthContext } from '../../context/AuthContext';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// interface Props {
//   orderBy: {
//     field: string;
//     direction: string;
//   };
//   setOrderBy: React.Dispatch<React.SetStateAction<OrderBy>>;
//   fromLinkedIn: boolean; // Boolean value for filtering LinkedIn posts
//   setFromLinkedIn: React.Dispatch<React.SetStateAction<boolean>>;
// }

// const QuickFilter: React.FC<Props> = ({
//   orderBy,
//   setOrderBy,
//   fromLinkedIn,
//   setFromLinkedIn,
// }) => {
//   const { t } = useTranslation();
//   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//   const open = Boolean(anchorEl);
//   const { user } = useContext(AuthContext);

//   const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <Box>
//       {/* Quick Filter Button */}
//       <Button
//         variant="outlined"
//         startIcon={<FlashOnIcon />}
//         endIcon={<KeyboardArrowDownIcon />}
//         onClick={handleOpen}
//         sx={{
//           borderRadius: '8px',
//           justifyContent: 'flex-start',
//           fontSize: '14px',
//         }}
//       >
//         {t('Quick filter')}
//       </Button>

//       {/* Dropdown Menu */}
//       <Menu
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         PaperProps={{
//           sx: {
//             width: '300px',
//             padding: '10px',
//             borderRadius: '8px',
//             boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
//             textAlign: 'left',
//           },
//         }}
//       >
//         <Typography
//           variant="subtitle1"
//           sx={{
//             marginBottom: '10px',
//             fontWeight: 'bold',
//             textAlign: 'left',
//             fontSize: '14px',
//           }}
//         >
//           {t('Quick filters')}
//         </Typography>

//         {/* Sort by Date Accordion */}
//         <Accordion
//           sx={{
//             '& .MuiAccordionSummary-root': {
//               justifyContent: 'flex-start',
//             },
//           }}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="sort-by-date-content"
//             id="sort-by-date-header"
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 gap: '8px',
//                 justifyContent: 'flex-start',
//               }}
//             >
//               <img
//                 src="./images/icons/sort.svg"
//                 alt="sort-icon"
//                 style={{ width: 16, height: 16 }}
//               />
//               <Typography sx={{ fontSize: '14px' }}>{t('Sort by date')}</Typography>
//             </Box>
//           </AccordionSummary>
//           <AccordionDetails>
//             <RadioGroup
//               value={`${orderBy.field} ${orderBy.direction}`}
//               onChange={(e) => {
//                 const [field, direction] = e.target.value.split(' ');
//                 setOrderBy({
//                   field: field as OrderByField,
//                   direction: direction as OrderByDirection,
//                 });
//               }}
//             >
//               <FormControlLabel
//                 value={`${OrderByField.CreatedOn} ${OrderByDirection.Desc}`}
//                 control={<Radio />}
//                 label={t('Creation date (newest to oldest)')}
//                 sx={{ fontSize: '14px', alignItems: 'flex-start' }}
//               />
//               <FormControlLabel
//                 value={`${OrderByField.CreatedOn} ${OrderByDirection.Asc}`}
//                 control={<Radio />}
//                 label={t('Creation date (oldest to newest)')}
//                 sx={{ fontSize: '14px', alignItems: 'flex-start' }}
//               />
//               <FormControlLabel
//                 value={`${OrderByField.PostedAt} ${OrderByDirection.Desc}`}
//                 control={<Radio />}
//                 label={t('Publication date (descending)')}
//                 sx={{ fontSize: '14px', alignItems: 'flex-start' }}
//               />
//               <FormControlLabel
//                 value={`${OrderByField.PostedAt} ${OrderByDirection.Asc}`}
//                 control={<Radio />}
//                 label={t('Publication date (ascending)')}
//                 sx={{ fontSize: '14px', alignItems: 'flex-start' }}
//               />
//             </RadioGroup>
//           </AccordionDetails>
//         </Accordion>

//         {/* Post Type Filter Accordion */}
//         {(user?.role?.includes(UserRole.BrandManager) ||
//           user?.role?.includes(UserRole.LimitedBrandManager)) && (
//           <Accordion
//             sx={{
//               '& .MuiAccordionSummary-root': {
//                 justifyContent: 'flex-start',
//               },
//             }}
//           >
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="post-type-filter-content"
//               id="post-type-filter-header"
//             >
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   gap: '8px',
//                   justifyContent: 'flex-start',
//                 }}
//               >
//                 <ArticleIcon sx={{ width: 16, height: 16 }} />
//                 <Typography sx={{ fontSize: '14px' }}>{t('Post type filter')}</Typography>
//               </Box>
//             </AccordionSummary>
//             <AccordionDetails>
//               <RadioGroup
//                 value={fromLinkedIn ? 'linkedin' : 'magnettu'}
//                 onChange={(e) => setFromLinkedIn(e.target.value === 'linkedin')}
//               >
//                 <FormControlLabel
//                   value="magnettu"
//                   control={<Radio />}
//                   label={t('Magnettu posts')}
//                   sx={{ fontSize: '14px', alignItems: 'flex-start' }}
//                 />
//                 <FormControlLabel
//                   value="linkedin"
//                   control={<Radio />}
//                   label={t('LinkedIn posts')}
//                   sx={{ fontSize: '14px', alignItems: 'flex-start' }}
//                 />
//               </RadioGroup>
//             </AccordionDetails>
//           </Accordion>
//         )}
//       </Menu>
//     </Box>
//   );
// };

// export default QuickFilter;
