import {
  Language,
  OrderByDirection,
  OrderByField,
  PostLifecycleState,
  PostLifecycleStateDisplay,
  User,
} from '../__generated__/graphql';

export type RoutesNames =
  | ''
  | 'calendar'
  | 'analytics'
  | 'rewards'
  | 'personal'
  | 'settings'
  | 'users'
  | 'content-manager';

export type CalendarEvent = {
  id: string;
  date: string;
  postingAccountName: string;
  avatar: string;
  backgroundColor: string;
  brandId: string;
  lifecycleState: PostLifecycleState;
  lifecycleStateDisplay: PostLifecycleStateDisplay;
  isIdea: boolean;
  title: string;
  preview: string;
};

export enum ContentBoardTabs {
  drafts = 'drafts',
  inbox = 'inbox',
  approved = 'approved',
  scheduled = 'scheduled',
  posted = 'posted',
  discarded = 'discarded',
  insights = 'insights',
}

export const getLifecycleStatesFromTab = (
  tabs: ContentBoardTabs[],
): PostLifecycleState[] => {
  const stateMap: { [key in ContentBoardTabs]?: PostLifecycleState[] } = {
    [ContentBoardTabs.drafts]: [PostLifecycleState.Draft],
    [ContentBoardTabs.inbox]: [
      PostLifecycleState.Ready,
      PostLifecycleState.PendingApproval,
      PostLifecycleState.Error,
    ],
    [ContentBoardTabs.approved]: [PostLifecycleState.Approved],
    [ContentBoardTabs.scheduled]: [PostLifecycleState.Scheduled],
    [ContentBoardTabs.posted]: [PostLifecycleState.Posted, PostLifecycleState.Posting],
    [ContentBoardTabs.discarded]: [PostLifecycleState.Discarded],
  };

  return tabs.flatMap((tab) => stateMap[tab] || []);
};

export const getTabsFromLifecycleStates = (
  states: PostLifecycleState[],
): ContentBoardTabs[] => {
  const tabMap: { [key in PostLifecycleState]?: ContentBoardTabs } = {
    [PostLifecycleState.Draft]: ContentBoardTabs.drafts,
    [PostLifecycleState.Ready]: ContentBoardTabs.inbox,
    [PostLifecycleState.PendingApproval]: ContentBoardTabs.inbox,
    [PostLifecycleState.Approved]: ContentBoardTabs.approved,
    [PostLifecycleState.Scheduled]: ContentBoardTabs.scheduled,
    [PostLifecycleState.Posted]: ContentBoardTabs.posted,
    [PostLifecycleState.Posting]: ContentBoardTabs.posted,
    [PostLifecycleState.Discarded]: ContentBoardTabs.discarded,
  };

  return Array.from(
    new Set(states.map((state) => tabMap[state]).filter(Boolean)),
  ) as ContentBoardTabs[];
};

export type OrderBy = {
  field: OrderByField;
  direction: OrderByDirection;
};
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type InitialStateType = {
  members: User[];
  selectedIds?: string[];
  disabledIds?: string[];
};
export enum ACTIONS_POST_ADVOCACY {
  ADD_MEMBERS = 'ADD_MEMBERS',
}

export type SelectMembersPayload = {
  [ACTIONS_POST_ADVOCACY.ADD_MEMBERS]: {
    members: User[];
  };
};
export type SelectMembersActions =
  ActionMap<SelectMembersPayload>[keyof ActionMap<SelectMembersPayload>];

// Extend the global Window interface to include `tolt_referral`
declare global {
  interface Window {
    tolt_referral?: string;
  }
}

export const DisplayLanguage: Record<Language, string> = {
  [Language.Dutch]: 'Dutch',
  [Language.English]: 'English (United States)',
  [Language.EnglishUk]: 'English (United Kingdom)',
  [Language.Flemish]: 'Flemish',
  [Language.French]: 'French',
  [Language.German]: 'German',
  [Language.Italian]: 'Italian',
  [Language.PortugueseBr]: 'Portuguese (Brazil)',
  [Language.PortuguesePt]: 'Portuguese (Portugal)',
  [Language.Spanish]: 'Spanish (Spain)',
  [Language.SpanishLatam]: 'Spanish (Latin America)',
};

export const getLifecycleStateDisplay = (
  lifecycleState: PostLifecycleState,
): PostLifecycleStateDisplay => {
  switch (lifecycleState) {
    case PostLifecycleState.Draft:
      return {
        name: 'Draft',
        color: '#E6E6E6',
        order: 1,
        isStateChangeable: true,
        isPostPostable: true,
      };
    case PostLifecycleState.Ready:
      return {
        name: 'Ready',
        color: '#E0C8FF',
        order: 2,
        isStateChangeable: true,
        isPostPostable: true,
      };
    case PostLifecycleState.PendingApproval:
      return {
        name: 'Pending Approval',
        color: '#FFF4DE',
        order: 3,
        isStateChangeable: true,
        isPostPostable: true,
      };
    case PostLifecycleState.Approved:
      return {
        name: 'Approved',
        color: '#A1D290',
        order: 4,
        isStateChangeable: true,
        isPostPostable: true,
      };
    case PostLifecycleState.Discarded:
      return {
        name: 'Discarded',
        color: '#FFA488',
        order: 5,
        isStateChangeable: true,
        isPostPostable: true,
      };
    case PostLifecycleState.Scheduled:
      return {
        name: 'Scheduled',
        color: '#D1E2FF',
        order: 6,
        isStateChangeable: false,
        isPostPostable: true,
      };
    case PostLifecycleState.Posted:
      return {
        name: 'Posted',
        color: '#7FBEBE',
        order: 7,
        isStateChangeable: false,
        isPostPostable: false,
      };
    case PostLifecycleState.Posting:
      return {
        name: 'Posting...',
        color: '#7FBEBE',
        order: 8,
        isStateChangeable: false,
        isPostPostable: false,
      };
    case PostLifecycleState.Error:
      return {
        name: 'Error',
        color: '#FF7A81',
        order: 8,
        isStateChangeable: false,
        isPostPostable: true,
      };
    default:
      return {
        name: lifecycleState
          .toLowerCase()
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
        color: '#bbb',
        isStateChangeable: true,
        isPostPostable: true,
      };
  }
};
