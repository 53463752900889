import { Snackbar, Zoom, Alert, AlertColor } from '@mui/material';
import React from 'react';

interface customSnackbarProps {
  severity: AlertColor;
  message: string | null;
  handleClose: () => void;
}

const CustomSnackbar = ({ severity, message, handleClose }: customSnackbarProps) => (
  <Snackbar
    open={!!message}
    onClose={handleClose}
    data-cy={`${severity}-snackbar`}
    autoHideDuration={5000}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    TransitionComponent={severity === 'error' ? Zoom : undefined}
  >
    <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
      {message}
    </Alert>
  </Snackbar>
);

export default CustomSnackbar;
