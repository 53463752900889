import {
  Box,
  Chip,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { optionsIndustries, BrandInput, suggestionsToneOfVoice } from './BrandData';
import { Language } from '../../__generated__/graphql';
import { DisplayLanguage } from '../../types';
import { useTranslation } from 'react-i18next';

interface BrandCorporateFormProps {
  control: Control<BrandInput>;
  inputStyle: any;
  styleChip: any;
  watchIndustry: string;
  watchSubIndustry: string;
  errors: FieldErrors<BrandInput>;
  setValue: UseFormSetValue<BrandInput>;
}

const BrandCorporateForm = ({
  control,
  inputStyle,
  styleChip,
  watchIndustry,
  watchSubIndustry,
  errors,
  setValue,
}: BrandCorporateFormProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" gap={1} fontSize="13px">
      <Typography fontSize="inherit">{t('Language preference')}</Typography>
      <Controller
        name="language"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select required value={value || ''} onChange={onChange} sx={inputStyle}>
            {Object.entries(Language).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {DisplayLanguage[value as keyof typeof DisplayLanguage]}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography fontSize="inherit">{t('Industry')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="industry"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                required
                value={value || ''}
                onChange={(e) => {
                  onChange(e.target.value);
                  setValue('subIndustry', '');
                  setValue('otherIndustries', '');
                }}
                sx={inputStyle}
                error={!!errors.industry}
              >
                {Object.keys(optionsIndustries).map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText error>{errors.industry?.message}</FormHelperText>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="subIndustry"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                required
                value={value || ''}
                onChange={(e) => {
                  onChange(e.target.value);
                  setValue('otherIndustries', '');
                }}
                sx={inputStyle}
                disabled={!watchIndustry || watchIndustry === 'Other industry'}
                error={!!errors.subIndustry}
              >
                {watchIndustry.length === 0 ? (
                  <MenuItem value="">{t('Select an Industry')}</MenuItem>
                ) : (
                  (optionsIndustries[watchIndustry] as string[]).map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(option)}
                    </MenuItem>
                  ))
                )}
              </Select>
            )}
          />
          <FormHelperText error>{errors.subIndustry?.message}</FormHelperText>
          {/* )} */}
        </Grid>
      </Grid>
      {(watchSubIndustry === 'Others' || watchIndustry === 'Other industry') && (
        <Controller
          name="otherIndustries"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextField
              required
              value={value}
              onChange={onChange}
              placeholder="Specify industry"
              sx={inputStyle}
              error={!!errors.otherIndustries}
              helperText={errors.otherIndustries?.message}
            />
          )}
        />
      )}
      <Typography fontSize="inherit">{t('Corporate tone of voice')}</Typography>
      <Controller
        name="corporateVoice"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box display="flex" flexWrap="wrap" gap={1} justifyContent={'center'}>
            {suggestionsToneOfVoice.map((option) => (
              <Chip
                key={option}
                label={t(option)}
                onClick={() =>
                  onChange(
                    value.includes(option)
                      ? value.filter((v) => v !== option)
                      : [...value, option],
                  )
                }
                sx={{
                  ...styleChip,
                  backgroundColor: value.includes(option) ? '#E7EBF0' : 'white',
                }}
              />
            ))}
          </Box>
        )}
      />
      {errors.corporateVoice && (
        <FormHelperText sx={{ textAlign: 'center' }} error>
          {errors.corporateVoice.message}
        </FormHelperText>
      )}

      <Typography fontSize="inherit">{t('Competitors')}</Typography>
      <Controller
        name="competitors"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            value={value}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.competitors}
            helperText={errors.competitors?.message}
          />
        )}
      />
      <Typography fontSize="inherit">
        {t('Keywords, phrases, or topics to avoid')}
      </Typography>
      <Controller
        name="negatives"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            value={value}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.negatives}
            helperText={errors.negatives?.message}
          />
        )}
      />
      <Typography fontSize="inherit">
        {t('Choose your favorite hashtags for your brand')}
      </Typography>
      <Controller
        name="hashtags"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            value={value}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.hashtags}
            helperText={errors.hashtags?.message}
          />
        )}
      />
      <Typography fontSize="inherit">
        {t('Value offered by your company and differentiation from competitors')}
      </Typography>
      <Controller
        name="competitiveAdvantage"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            multiline
            rows={3}
            value={value}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.competitiveAdvantage}
            helperText={errors.competitiveAdvantage?.message}
          />
        )}
      />
      <Typography fontSize="inherit">{t('Signature')}</Typography>
      <Controller
        name="signature"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            value={value ? value.replace(/<br\s*\/?>/g, '\n') : ''}
            multiline
            rows={6}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.signature}
            helperText={errors.signature?.message}
            placeholder={t(
              'Add a signature to use on your posts.\n\nFor example:\n-------\nLooking for more content like this? Connect with me and let’s keep in touch!',
            )}
          />
        )}
      />
    </Box>
  );
};

export default BrandCorporateForm;
