import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import Avatar from '../Avatar/Avatar';

interface Props {
  pages: { name: string; urn: string; avatar: string }[];
  open: boolean;
  onClose: () => void;
  onSubmit: (page: { name: string; urn: string; avatar: string }) => void;
}

const LinkedinPageSelector = ({ pages, open, onClose, onSubmit }: Props) => {

  return (
    <Dialog onClose={onClose} open={open} maxWidth={'sm'} sx={{
      zIndex: 9999
    }}>
      <DialogTitle>
        {pages.length ? (
          <Typography fontWeight={'bold'}>Select the page you want to connect</Typography>
        ) : (
          <Typography fontWeight={'bold'}>
            We couldn’t find any LinkedIn pages linked to your account
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        {pages.length > 0 ? (
          <List>
            {pages.map((page) => (
              <ListItem disablePadding key={page.urn} aria-selected={true}>
                <ListItemButton
                  onClick={() => {
                    onSubmit(page);
                  }}
                  sx={{ gap: 1, borderRadius: '10px' }}
                >
                  <Avatar avatar={page.avatar || ''} name={page.name} />
                  <ListItemText primary={page.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>
            Make sure you have Admin permissions of the page you want to connect
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default LinkedinPageSelector;
