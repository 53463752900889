import { Box, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';
import { BrandInput, generatePostOptions, typeContentConsumedOptions } from './BrandData';
import { useTranslation } from 'react-i18next';
interface BrandPersonalIdeationFormProps {
  control: Control<BrandInput, any>;
  inputStyle: any;
  styleChip: any;
  errors: FieldErrors<BrandInput>;
  watch: UseFormWatch<BrandInput>;
}

const BrandPersonalIdeationForm = ({
  control,
  inputStyle,
  // errors,
  watch,
}: BrandPersonalIdeationFormProps) => {
  const { t } = useTranslation();
  const watchContentConsumption = watch('contentConsumption');

  return (
    <Box display="flex" flexDirection="column" gap={1} fontSize="13px">
      <Typography fontSize="inherit">
        {t('What type of content do you usually consume?')}
      </Typography>
      <Controller
        name="contentConsumption"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            required
            value={value || ''}
            onChange={onChange}
            sx={inputStyle}
            multiple
          >
            {typeContentConsumedOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {t(option)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {watchContentConsumption?.includes('Other (please specify)') && (
        <Controller
          name="otherContentConsumption"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextField
              required
              value={value}
              onChange={onChange}
              sx={inputStyle}
              placeholder="Other (please specify)"
            />
          )}
        />
      )}

      <Typography fontSize="inherit">
        {t(
          'How would you like to structure your topics per week? (In case Magnettu creates the content for you)',
        )}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography fontSize="inherit">{t('1st Post')}</Typography>
          <Controller
            name="firstPost"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select required value={value || ''} onChange={onChange} sx={inputStyle}>
                {generatePostOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize="inherit">{t('2nd Post')}</Typography>
          <Controller
            name="secondPost"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select required value={value || ''} onChange={onChange} sx={inputStyle}>
                {generatePostOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize="inherit">{t('3rd Post')}</Typography>
          <Controller
            name="thirdPost"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select required value={value || ''} onChange={onChange} sx={inputStyle}>
                {generatePostOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize="inherit">{t('4th Post')}</Typography>
          <Controller
            name="fourthPost"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select required value={value || ''} onChange={onChange} sx={inputStyle}>
                {generatePostOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>

      <Typography fontSize="inherit">
        {t('Are there world leaders you admire or find inspiring?')}
      </Typography>
      <Controller
        name="worldLeaderInspiration"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value || ''} onChange={onChange} sx={inputStyle} />
        )}
      />
      <Typography fontSize="inherit">
        {t('Are there leaders in your specific industry whom you admire?')}
      </Typography>
      <Controller
        name="industryLeaderInspiration"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value || ''} onChange={onChange} sx={inputStyle} />
        )}
      />
      <Typography fontSize="inherit">
        {t('What are your hobbies or passions?')}
      </Typography>
      <Controller
        name="hobbies"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value || ''} onChange={onChange} sx={inputStyle} />
        )}
      />
      <Typography fontSize="inherit">
        {t('Who are the writers and artists who inspire you?')}
      </Typography>
      <Controller
        name="artistsInspiration"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value || ''} onChange={onChange} sx={inputStyle} />
        )}
      />
      <Typography fontSize="inherit">
        {t('Are there specific values you want to reflect in your personal brand?')}
      </Typography>
      <Controller
        name="valuesBrand"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value || ''} onChange={onChange} sx={inputStyle} />
        )}
      />
      <Typography fontSize="inherit">
        {t(
          'Is there any political or social activity you want to reflect in your personal brand?',
        )}
      </Typography>
      <Controller
        name="politicalSocialActivityBrand"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            multiline
            rows={3}
            value={value || ''}
            onChange={onChange}
            sx={inputStyle}
          />
        )}
      />
      <Typography fontSize="inherit">
        {t(
          'Is there any emerging topic or trend in which you would like to position yourself as a thought leader?',
        )}
      </Typography>
      <Controller
        name="trendingTopics"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value || ''} onChange={onChange} sx={inputStyle} />
        )}
      />
      <Typography fontSize="inherit">
        {t('Generally, what media outlets do you use or trust the most?')}
      </Typography>
      <Controller
        name="trustedMedia"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value || ''} onChange={onChange} sx={inputStyle} />
        )}
      />
    </Box>
  );
};

export default BrandPersonalIdeationForm;
