import { useMutation, useQuery } from '@apollo/client';
import { ReactNode, createContext, useContext, useState } from 'react';
import {
  Brand,
  GetGroupsByUserQuery,
  Group,
  SocialMediaAccountType,
  TypeBrand,
  UserRole,
} from '../__generated__/graphql';
import {
  ADD_SOCIAL_MEDIA_ACCOUNT,
  DELETE_BRAND,
  DELETE_GROUP,
  REMOVE_SOCIAL_MEDIA_ACCOUNT,
} from '../graphql/mutations';
import {
  GET_BRANDS,
  GET_BRANDS_MEMBER_EDITOR,
  GET_CURRENT_USER,
  GET_GROUPS_BY_USER,
  GET_PUBLIC_BRAND,
} from '../graphql/queries';
import { AuthContext } from './AuthContext';
import { SnackbarContext } from './SnackbarContext';
import { useTranslation } from 'react-i18next';

interface BrandContextType {
  socialAuthModal: boolean;
  handleSocialAuthModal: () => void;
  pages: any[];
  connectNewAccountOpen: boolean;
  setConnectNewAccountOpen: (value: boolean) => void;
  handleSaveLinkedinCompanyCredentials: (page: {
    urn: string;
    name: string;
    avatar: string;
  }) => void;
  linkedinPageSelectorOpen: string | null;
  setLinkedinPageSelectorOpen: (value: string | null) => void;
  handleRemoveSocialMediaAccount: (id: string) => void;
  handleRenewAccess: (
    type: SocialMediaAccountType,
    redirect: string,
    postId?: string,
    brandId?: string,
  ) => void;
  fetchLinkedinProfile: (token: string, avatar: string) => void;
  fetchLinkedinOrganizationPages: (token: string) => void;
  modalNewBrand: boolean;
  setModalNewBrand: (value: boolean) => void;
  brandSelected: Brand | null;
  handleBrandSelected: (value: Brand) => void;
  dataBrands: Brand[];
  dataBrandsMemberEditor: Brand[];
  handleDeleteBrand: (id: string) => void;
  handleDeleteGroup: (id: string) => void;
  handleConnectNewAccount: (type: TypeBrand, url: string, isModal?: boolean) => void;
  tabModal: number;
  setTabModal: (value: number) => void;
  handleModalNewBrandFromCallback: (token: string, avatar: string) => void;
  handleCloseModal: () => void;
  branIdLocalStorage: string | null;
  handleOpenModal: () => void;
  refetch: () => void;
  openModalCompany: boolean;
  setOpenModalCompany: (value: boolean) => void;
  setBrandSelected: (value: Brand | null) => void;
  newBrandData: Brand | null;
  shellGroups: GetGroupsByUserQuery | undefined;
  groupSelected: Group | null;
  // setGroupSelected: (value: Group | null) => void;
  handleGroupSelected: (value: Group) => void;
  refetchGroups: () => void;
  resetSelectedOptions: () => void;
  isReadyForModal: boolean;
  loading: boolean;
  loadingUpdateBrandAccount: boolean;
  setLoadingUpdateBrandAccount: (value: boolean) => void;
  loadingButtonRenew: boolean;
  setLoadingButtonRenew: (value: boolean) => void;
}

const initialState: BrandContextType = {
  socialAuthModal: false,
  handleSocialAuthModal: () => {},
  pages: [],
  connectNewAccountOpen: false,
  setConnectNewAccountOpen: () => {},
  handleSaveLinkedinCompanyCredentials: () => {},
  linkedinPageSelectorOpen: null,
  setLinkedinPageSelectorOpen: () => {},
  handleRemoveSocialMediaAccount: () => {},
  handleRenewAccess: () => {},
  fetchLinkedinProfile: () => {},
  fetchLinkedinOrganizationPages: () => {},
  modalNewBrand: false,
  setModalNewBrand: () => {},
  brandSelected: null,
  handleBrandSelected: () => {},
  dataBrands: [],
  dataBrandsMemberEditor: [],
  handleDeleteBrand: () => {},
  handleDeleteGroup: () => {},
  handleConnectNewAccount: () => {},
  tabModal: 0,
  setTabModal: () => {},
  handleModalNewBrandFromCallback: () => {},
  handleCloseModal: () => {},
  branIdLocalStorage: null,
  handleOpenModal: () => {},
  refetch: () => {},
  openModalCompany: false,
  setOpenModalCompany: () => {},
  setBrandSelected: () => {},
  newBrandData: null,
  shellGroups: undefined,
  groupSelected: null,
  handleGroupSelected: () => {},
  refetchGroups: () => {},
  resetSelectedOptions: () => {},
  isReadyForModal: false,
  loading: false,
  loadingUpdateBrandAccount: false,
  setLoadingUpdateBrandAccount: () => {},
  loadingButtonRenew: false,
  setLoadingButtonRenew: () => {},
};

export const BrandContext = createContext<BrandContextType>(initialState);

export const BrandProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  // Mutations

  const [addSocialMediaAccount, { loading: loadingAddSocialMediaAccount }] = useMutation(
    ADD_SOCIAL_MEDIA_ACCOUNT,
  );
  const [removeSocialMediaAccount, { loading: loadingRemoveSocialMediaAccount }] =
    useMutation(REMOVE_SOCIAL_MEDIA_ACCOUNT);
  const [deleteBrand, { loading: loadingDeleteBrand }] = useMutation(DELETE_BRAND);
  const [deleteGroup, { loading: loadingDeleteGroup }] = useMutation(DELETE_GROUP);

  // Contexts
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);
  const {
    user,
    refetchCurrentUser,
    loading: loadingCurrentUser,
  } = useContext(AuthContext);

  // Queries
  const { data: shellGroups, refetch: refetchGroups } = useQuery(GET_GROUPS_BY_USER, {
    skip:
      !user ||
      (!user.role.includes(UserRole.BrandManager) &&
        !user.role.includes(UserRole.LimitedBrandManager)),
    onCompleted: (data) => {
      if (groupSelected && data.shellGroups) {
        setGroupSelected(
          data?.shellGroups.find((group) => group?._id === groupSelected._id) as Group,
        );
      }
    },
  });

  const {
    data: dataBrands,
    loading: loadingGetBrands,
    refetch: refetchBrands,
  } = useQuery(GET_BRANDS, {
    skip:
      !user ||
      (!user.role.includes(UserRole.BrandManager) &&
        !user.role.includes(UserRole.LimitedBrandManager)),
    onCompleted: (data) => {
      setIsReadyForModal(true);
      if (brandIdUserLocalStorage) {
        const brand = data.getBrands.find(
          (brand) => brand!._id === brandIdUserLocalStorage,
        ) as Brand;
        setBrandSelected(brand);
      }
    },
  });

  const { data: dataBrandsMemberEditor } = useQuery(GET_BRANDS_MEMBER_EDITOR, {
    variables: {},
    skip: !user || !user.role.includes(UserRole.GroupMembersEditor),
  });

  const refetch = () => {
    if (
      user &&
      (user.role.includes(UserRole.BrandManager) ||
        user.role.includes(UserRole.LimitedBrandManager))
    ) {
      refetchBrands();
    }
  };

  // State

  const [socialAuthModal, setSocialAuthModal] = useState<boolean>(false);
  const [pages, setPages] = useState([]);
  const [connectNewAccountOpen, setConnectNewAccountOpen] = useState(false);
  const [linkedinPageSelectorOpen, setLinkedinPageSelectorOpen] = useState<string | null>(
    null,
  );
  const [modalNewBrand, setModalNewBrand] = useState<boolean>(false);
  const [brandSelected, setBrandSelected] = useState<Brand | null>(null);
  const [groupSelected, setGroupSelected] = useState<Group | null>(null);
  const [newBrandData, setNewBrandData] = useState<Brand | null>(null);
  const [isReadyForModal, setIsReadyForModal] = useState<boolean>(false);
  const [loadingUpdateBrandAccount, setLoadingUpdateBrandAccount] =
    useState<boolean>(false);
  const [loadingButtonRenew, setLoadingButtonRenew] = useState<boolean>(false);

  const [tabModal, setTabModal] = useState(0);
  const [openModalCompany, setOpenModalCompany] = useState(false);

  // Constants

  const newBrandIdLocalStorage = localStorage.getItem('newBrandId') as string;
  const brandIdUserLocalStorage = localStorage.getItem('brandIdUser') as string;

  const loading =
    loadingAddSocialMediaAccount ||
    loadingDeleteBrand ||
    loadingDeleteGroup ||
    loadingGetBrands ||
    loadingRemoveSocialMediaAccount ||
    loadingCurrentUser;

  // Functions
  const handleConnectNewAccount = (
    type: TypeBrand,
    redirect: string,
    isModal = false,
  ) => {
    if (type === TypeBrand.Personal) {
      handleConnectLinkedin(redirect, isModal);
    } else {
      handleConnectLinkedinCompany(redirect, isModal);
    }
  };

  const handleModalNewBrandFromCallback = (token: string, avatar: string) => {
    if (newBrandIdLocalStorage && dataBrands?.getBrands) {
      const dataBrand = dataBrands?.getBrands?.find(
        (brand) => brand!._id === newBrandIdLocalStorage,
      ) as Brand;
      setNewBrandData(dataBrand);

      if (dataBrand?.typeBrand === TypeBrand.Personal) {
        fetchLinkedinProfile(token, avatar, true);
      } else {
        fetchLinkedinOrganizationPages(token, true);
      }
    }
    setTabModal(2);
    setModalNewBrand(true);
  };

  const handleBrandSelected = (brand: Brand) => {
    localStorage.setItem('brandIdUser', brand._id);
    setBrandSelected(brand);
    setGroupSelected(null);
  };

  const handleSocialAuthModal = () => {
    setSocialAuthModal(!socialAuthModal);
  };

  const resetSelectedOptions = () => {
    setBrandSelected(null);
    setGroupSelected(null);
    localStorage.removeItem('brandIdUser');
  };

  const handleConnectLinkedinCompany = (
    redirect: string,
    isModal = false,
    postId?: string,
    brandId?: string,
  ) => {
    window.location.assign(
      `${
        process.env.REACT_APP_SERVER_URL
      }/api/linkedin/organization/authorization?redirect=${redirect}&organization=true&isModal=${isModal}${
        postId ? `&postId=${postId}` : ''
      }`,
    );
  };

  const fetchLinkedinOrganizationPages = (token: string, modal: boolean = false) => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/linkedin/organization/pages?linkedinToken=${token}`,
      {
        method: 'GET',
      },
    )
      .then((res) => res.json())
      .then(({ success, data, message }) => {
        if (!success) {
          setLoadingButtonRenew(false);
          setErrorMessage(message);
          return;
        }
        setLinkedinPageSelectorOpen(token);

        if (!modal) {
          setOpenModalCompany(true);
        }

        setPages(data);

      });
  };

  const fetchLinkedinProfile = (
    token: string,
    avatar: string,
    modal: boolean = false,
  ) => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/linkedin/profile?linkedinToken=${token}`,
      {
        method: 'GET',
      },
    )
      .then((res) => res.json())
      .then(({ success, data, message }) => {
        if (!success) {
          setErrorMessage(message);
          return;
        }

        const { urn, name } = data;
        addSocialMediaAccount({
          variables: {
            input: {
              type: SocialMediaAccountType.LinkedinPersonal,
              id: urn,
              name: name,
              linkedinUrn: urn,
              token: token!,
              avatar: avatar,
              brandId: modal ? newBrandIdLocalStorage : brandIdUserLocalStorage,
            },
          },
          onError: (err) => {
            setErrorMessage(`Linkedin account coudn't be connected due to an error`);
          },
          onCompleted: (data) => {
            if (!data.addSocialMediaAccount.success) {
              setErrorMessage(
                data.addSocialMediaAccount.message || 'Something went wrong',
              );
              return;
            }
            refetch();
            refetchCurrentUser();
            if (newBrandIdLocalStorage) {
              setBrandSelected(
                dataBrands?.getBrands?.find(
                  (brand) => brand!._id === newBrandIdLocalStorage,
                ) as Brand,
              );
            }
            setSuccessMessage(t('Cuenta conectada con exito'));
            setLoadingUpdateBrandAccount(false);
            return;
          },
          refetchQueries: [GET_PUBLIC_BRAND],
          awaitRefetchQueries: true,
        });
      });
  };

  const handleConnectLinkedin = (
    redirect: string,
    isModal = false,
    postId?: string,
    brandId?: string,
  ) => {
    if (redirect.includes('settings') && !isModal && user?.brand) {
      localStorage.setItem('brandIdUser', postId && brandId ? brandId : user.brand._id);
    }
    window.location.assign(
      `${
        process.env.REACT_APP_SERVER_URL
      }/api/linkedin/authorization?redirect=${redirect}&isModal=${isModal}${
        postId ? `&postId=${postId}` : ''
      }`,
    );
    // setLoadingButtonRenew(false);
  };

  const handleSaveLinkedinCompanyCredentials = (page: {
    urn: string;
    name: string;
    avatar: string;
  }) => {
    addSocialMediaAccount({
      variables: {
        input: {
          type: SocialMediaAccountType.LinkedinPage,
          id: page.urn,
          name: page.name,
          linkedinUrn: page.urn,
          avatar: page.avatar,
          token: linkedinPageSelectorOpen as string,
          brandId: modalNewBrand ? newBrandIdLocalStorage : brandIdUserLocalStorage,
        },
      },
      onCompleted: (data) => {
        if (!data.addSocialMediaAccount.success) {
          setErrorMessage(data.addSocialMediaAccount.message || 'Something went wrong');
          setLoadingUpdateBrandAccount(false);
          return;
        }
        handleCloseModal();

        setSuccessMessage(`LinkedIn page ${page.name} is now linked`);
        setOpenModalCompany(false);
      },
      onError: (err) => {
        setErrorMessage(
          `LinkedIn company page not connected. Please retry or contact magnettu support`,
        );
      },
      refetchQueries: [GET_CURRENT_USER],
    });
  };

  const handleRemoveSocialMediaAccount = (id: string) => {
    removeSocialMediaAccount({
      variables: {
        brandId: id,
      },
      onCompleted: ({ removeSocialMediaAccount }) => {
        if (!removeSocialMediaAccount.success) {
          setErrorMessage(removeSocialMediaAccount.message || 'Something went wrong');
          return;
        }
        setSuccessMessage(
          removeSocialMediaAccount.message || 'Social media account removed',
        );
        refetch();
        refetchCurrentUser();
      },
      onError: (err) => {
        setErrorMessage(`Social media account coudn't be removed due to an error`);
      },
    });
  };

  const handleRenewAccess = (
    type: SocialMediaAccountType,
    redirect: string,
    postId?: string,
    brandId?: string,
  ) => {
    if (postId && brandId) {
      localStorage.removeItem('brandIdUser');
      localStorage.setItem('brandIdUser', brandId);
    }

    if (type === SocialMediaAccountType.LinkedinPersonal) {
      handleConnectLinkedin(redirect, false, postId, brandId);
    } else if (type === SocialMediaAccountType.LinkedinPage) {
      handleConnectLinkedinCompany(redirect, false, postId, brandId);
    }
  };

  const handleDeleteBrand = (id: string) => {
    deleteBrand({
      variables: {
        brandId: id,
      },
      onCompleted: (data) => {
        if (data.deleteBrand!.success) {
          setSuccessMessage(data.deleteBrand!.message || 'Something went wrong');
          refetch();
          return;
        } else {
          setErrorMessage(data.deleteBrand!.message || 'Something went wrong');
        }
      },
      onError: (err) => {
        setErrorMessage(`Brand coudn't be removed due to an error`);
      },
      refetchQueries: [GET_CURRENT_USER],
    });
  };

  const handleDeleteGroup = (id: string) => {
    deleteGroup({
      variables: {
        groupId: id,
      },
      onCompleted: (data) => {
        if (data.deleteGroup!.success) {
          setSuccessMessage(data.deleteGroup!.message || 'Group removed successfully');
          setGroupSelected(null);
          refetchGroups();
          return;
        }
        setErrorMessage(data.deleteGroup!.message || 'Something went wrong');
      },
      onError: (err) => {
        setErrorMessage(`Group coudn't be removed due to an error`);
      },
    });
  };

  const handleCloseModal = () => {
    setSocialAuthModal(false);
    setConnectNewAccountOpen(false);
    setLinkedinPageSelectorOpen(null);
    setModalNewBrand(false);
    localStorage.removeItem('newBrandId');
    setPages([]);
    setTabModal(0);
    setLoadingUpdateBrandAccount(false);
    refetch();
  };

  const handleOpenModal = () => {
    setModalNewBrand(true);
    setNewBrandData(null);
    localStorage.removeItem('newBrandId');
  };

  const handleGroupSelected = (group: Group) => {
    setGroupSelected(group);
    setBrandSelected(null);
    localStorage.removeItem('brandIdUser');
  };

  return (
    <BrandContext.Provider
      value={{
        socialAuthModal,
        handleSocialAuthModal,
        pages,
        connectNewAccountOpen,
        setConnectNewAccountOpen,
        handleSaveLinkedinCompanyCredentials,
        linkedinPageSelectorOpen,
        setLinkedinPageSelectorOpen,
        handleRemoveSocialMediaAccount,
        handleRenewAccess,
        fetchLinkedinProfile,
        fetchLinkedinOrganizationPages,
        modalNewBrand,
        setModalNewBrand,
        brandSelected,
        handleBrandSelected,
        dataBrands: (dataBrands?.getBrands || []) as Brand[],
        handleDeleteBrand,
        handleConnectNewAccount,
        tabModal,
        setTabModal,
        handleModalNewBrandFromCallback,
        handleCloseModal,
        branIdLocalStorage: newBrandIdLocalStorage,
        handleOpenModal,
        refetch,
        openModalCompany,
        setOpenModalCompany,
        setBrandSelected,
        newBrandData,
        shellGroups,
        groupSelected,
        handleGroupSelected,
        refetchGroups,
        handleDeleteGroup,
        resetSelectedOptions,
        isReadyForModal,
        loading,
        dataBrandsMemberEditor: (dataBrandsMemberEditor?.getBrandsMemberEditor ||
          []) as Brand[],
        loadingUpdateBrandAccount,
        setLoadingUpdateBrandAccount,
        loadingButtonRenew,
        setLoadingButtonRenew,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};
