import {
  FormatBoldRounded,
  InsertEmoticon,
  Lock,
  LockOpen,
  Redo,
  Undo,
} from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {
  Box,
  Divider,
  IconButton as MuiIconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { Editor, EditorContent } from '@tiptap/react';
import { useContext, useRef, useState, useEffect } from 'react';
import { PostContext } from '../../context/PostContext';
import { PostHandlers, PostStatus, UsePostReturnType } from '../../hooks/usePost';
import { convertToBold } from '../../utils';
import AIAssistantMenu from '../AiAssistant/AIAssistantMenu';
import EmojiComponent from '../SelectorComponents/EmojiSelector';
import TaggerSelector from '../SelectorComponents/TaggerSelector';
import CharCounter from './CharCounter';
import './styles.css';
import { PostType, Tag } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import SignIcon from '../../assets/sign.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePostEditor } from '../../hooks/usePostEditor';

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  width: '20px',
  '& .MuiSvgIcon-root': {
    width: '20px',
  },
  '&.MuiIconButton-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  postId?: string;
  content?: string | null;
  placeholder?: string;
  status?: PostStatus;
  handlers?: PostHandlers;
  aiAssistant?: boolean;
  resizable?: boolean;
  onChange?: (html: string, json: any, action?: Tag) => void;
  advocacyEditable?: boolean;
  toggleAdvocacyEditable?: () => void;
  isUndoable?: boolean;
  isLink?: boolean;
  isAdmin?: boolean;
}

export const TextEditor = ({
  postId,
  content = null,
  status,
  aiAssistant = false,
  onChange,
  advocacyEditable,
  toggleAdvocacyEditable,
  isUndoable = false,
  isLink = false,
  isAdmin = false,
}: Props) => {
  const { t } = useTranslation();
  const [emojiComponentOpen, setEmojiComponentOpen] = useState(false);
  const [taggerComponentOpen, setTaggerComponentOpen] = useState(false);

  const [showNoSignatureModal, setShowNoSignatureModal] = useState(false);
  const { postState, editor: EditorAdmin } = useContext(PostContext);
  const postRef = postState?.postRef;

  const editorUser = usePostEditor({
    postState: postState as UsePostReturnType,
    content: content ?? '',
    editable: !status?.isEditDisabled && !status?.isSaving,
    postRef: postState?.postRef,
    onChange,
  });

  const editor = isAdmin ? EditorAdmin : editorUser;

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const anchorRef = useRef(null);
  // Function to check if the signature is present in the editor content
  const isSignaturePresent = (editor: Editor) => {
    if (!editor) return false;

    const editorContent = postState?.postRef?.current?.content?.body || '';

    // Check if there is a span with the signature class
    const parser = new DOMParser();
    const doc = parser.parseFromString(editorContent, 'text/html');
    const signatureElement = doc.querySelector('.signature');

    return !!signatureElement; // Returns true if signature is found, false otherwise
  };

  const [isSignatureChecked, setIsSignatureChecked] = useState(
    editor ? isSignaturePresent(editor) : false,
  );

  useEffect(() => {
    if (onChange && postState?.post._id) {
      onChange(postState?.post.content?.body as string, postState?.post.content?.json);
    }
  }, [
    onChange,
    postState?.post._id,
    postState?.post.content?.body,
    postState?.post.content?.json,
  ]);

  const previousContentRef = useRef<string | null>(null);

  console.log(window.tolt_referral);

  useEffect(() => {
    if (!editor || !postState?.postRef?.current) return;

    const refContent = postState.postRef.current.content?.body || '';
    const currentContent = editor.getHTML();

    // Use a flag to determine if updates are needed to avoid conflicts
    const shouldUpdate =
      currentContent !== refContent && refContent !== previousContentRef.current;

    if (shouldUpdate) {
      // Ensure editor updates don't interfere with in-progress typing or actions
      const timeout = setTimeout(() => {
        editor.commands.setContent(refContent);
        previousContentRef.current = refContent; // Prevent redundant updates
      }, 300); // Debounce the update to avoid immediate overwrite

      return () => clearTimeout(timeout); // Clean up timeout to avoid memory leaks
    }
  }, [editor, postState?.postRef?.current?.content?.body, postState?.postRef]);

  const insertSignature = (editor: Editor): void => {
    if (postState?.post?.brand?.signature && postState?.post?.brand?.signature !== '') {
      removeSignature(editor); // Ensure only one signature exists
      const parser = new DOMParser();
      const currentContent = postRef?.current?.content?.body || '';
      const doc = parser.parseFromString(currentContent, 'text/html');

      // Find the last <p class="tiptap-p"> tag
      let targetParagraph = Array.from(doc.querySelectorAll('p.tiptap-p')).pop();

      if (!targetParagraph) {
        targetParagraph = doc.createElement('p');
        targetParagraph.className = 'tiptap-p';
        doc.body.appendChild(targetParagraph);
      }

      // Wrap the signature inside a <span> with a class for easy removal
      const signatureWrapper = `<span class="signature"><br>${postState?.post?.brand?.signature}</span>`;
      targetParagraph.innerHTML += signatureWrapper;

      // Serialize the updated content back to HTML
      const updatedContent = doc.body.innerHTML;

      // Update postRef content
      if (postRef?.current) {
        postRef.current.content = {
          ...postRef.current.content,
          body: updatedContent,
        };
      }
      if (postState?.post?.content) {
        postState.post.content.body = updatedContent;
      }
      // Focus at the end of the content
      editor.commands.focus('end');
    } else {
      // Logged-in user doesn't have a signature, show modal
      setShowNoSignatureModal(true);
    }
  };

  const removeSignature = (editor: Editor): void => {
    const parser = new DOMParser();
    const currentContent = postRef?.current?.content?.body || '';
    const doc = parser.parseFromString(currentContent, 'text/html');

    // Find and remove the signature
    const signatureElement = doc.querySelector('.signature');
    if (signatureElement) {
      signatureElement.remove();
    }

    // Serialize back to HTML
    const updatedContent = doc.body.innerHTML;

    // Update postRef content
    if (postRef?.current) {
      postRef.current.content = {
        ...postRef.current.content,
        body: updatedContent,
      };
    }
  };

  const handleSignatureToggle = (): void => {
    if (!editor) return;

    if (isSignatureChecked) {
      // Remove the existing signature if it's already inserted
      removeSignature(editor);
      setIsSignatureChecked(false);
    } else {
      // Try to insert the signature based on the user (logged-in or brand user)
      insertSignature(editor);
      setIsSignatureChecked(true);
    }
  };

  const handleEmojiComponentOpen = () => {
    setEmojiComponentOpen(true);
  };

  const handleTaggerComponentOpen = () => {
    setTaggerComponentOpen(true);
  };

  const handleTaggerComponentClose = () => {
    setTaggerComponentOpen(false);
  };

  const handleEmojiComponentClose = () => {
    setEmojiComponentOpen(false);
  };

  const handleEmojiInputChange = (editor: Editor, emoji: string) => {
    editor.commands.insertContent(emoji);
    onChange && onChange(editor.getHTML(), editor.getJSON());
  };

  const handleTagger = (editor: Editor, tag: string, tagId: string) => {
    editor.commands.insertContent(
      `<span data-type="mention" class="mention" data-label="${tag}" data-id="${tagId}" contenteditable="false">${tag}</span>`,
    );
    editor.commands.focus();
    setTaggerComponentOpen(false);
    onChange && onChange(editor.getHTML(), editor.getJSON());
  };

  const setBold = (editor: Editor) => {
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    if (from !== undefined && to !== undefined) {
      const selectedText = state.doc.textBetween(from, to);
      const boldText = convertToBold(selectedText);
      const textNode = editor.schema.text(boldText);
      const transaction = state.tr.replaceWith(from, to, textNode);
      view.dispatch(transaction);
    }
  };

  const handleUndo = () => {
    editor?.commands.undo();
  };

  const handleRedo = () => {
    editor?.commands.redo();
  };

  return (
    <Stack direction="column" height={'100%'} spacing={1} width={'100%'}>
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #BBBBBB',
          borderRadius: '8px',
          cursor: 'text',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <Box
          minHeight={300}
          sx={{
            display: 'block',
            overflowY: 'auto',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          {editor && <EditorContent editor={editor} style={{ height: '100%' }} />}
        </Box>
        <Box
          height={'fit-content'}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
        >
          {!status?.isEditDisabled && (
            <Stack
              direction="row"
              justifyContent={isLink ? 'flex-end' : 'space-between'}
              sx={{
                width: '100%',
                bottom: 0,
                p: '10px 20px 10px 10px',
              }}
            >
              {editor && aiAssistant && !isLink && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <AIAssistantMenu
                    postId={postId}
                    editor={editor}
                    onChange={onChange as any}
                    onLoading={(state) => {
                      editor?.setOptions({
                        editable: !state,
                      });
                    }}
                  />
                </Box>
              )}
              <Stack direction="row" gap={1} alignItems="center">
                {status?.isAdvocacyParent && (
                  <Tooltip title="Let others edit the content" arrow placement="top">
                    <IconButton onClick={toggleAdvocacyEditable}>
                      {advocacyEditable ? <LockOpen /> : <Lock />}
                    </IconButton>
                  </Tooltip>
                )}
                {status?.charsCount !== undefined && <CharCounter />}
                {editor && (
                  <Tooltip title={t('Bold')} placement="top">
                    <IconButton onClick={() => setBold(editor)}>
                      <FormatBoldRounded />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={t('Insert mention')} placement="top">
                  <IconButton onClick={handleTaggerComponentOpen} ref={anchorRef}>
                    <AlternateEmailIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Insert emoji')} placement="top">
                  <IconButton
                    data-testid="emoji-button"
                    onClick={handleEmojiComponentOpen}
                    ref={anchorRef}
                  >
                    <InsertEmoticon />
                  </IconButton>
                </Tooltip>
                {showNoSignatureModal && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '50px',
                      left: '80%',
                      transform: 'translateX(-40%)',
                      backgroundColor: 'white',
                      border: '1px solid #BBBBBB',
                      borderRadius: '8px',
                      padding: '20px',
                      zIndex: 1000,
                    }}
                  >
                    <Typography variant="body2">
                      {(postState?.post?.brand?.user?._id ||
                        postState?.post?.brand?.userId) === user?._id
                        ? t("You don't have a signature.")
                        : `${`${postState?.post?.brand?.name || 'The brand user'} 
                           `.trim()} ${t("doesn't have a signature.")}`}
                    </Typography>

                    {/* Show 'Add one' prompt only if the logged-in user is missing the signature */}
                    {(postState?.post?.brand?.user?._id ||
                      postState?.post?.brand?.userId) === user?._id ? (
                      <span
                        style={{ color: 'hotpink', cursor: 'pointer' }}
                        onClick={() => {
                          navigate('/settings', {
                            state: { tab: 0, openEssentialInfo: true },
                          });
                        }}
                      >
                        {t('Add one')}
                      </span>
                    ) : (
                      <span
                        style={{ color: 'hotpink', cursor: 'pointer' }}
                        onClick={() => {
                          navigate('/brands', {
                            state: {
                              brand: postState?.post?.brand,
                              openEssentialInfoBrands: true,
                            },
                          });
                        }}
                      >
                        {t('Add one')}
                      </span>
                    )}

                    <IconButton onClick={() => setShowNoSignatureModal(false)}>
                      <Undo />
                    </IconButton>
                  </Box>
                )}
                {!isLink && postState?.post?.type !== PostType.AdvocacyParent && (
                  <Tooltip title={t('Insert signature')} placement="top">
                    <Box
                      sx={{
                        position: 'relative', // Parent must be relative for Chip's absolute positioning
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px', // Set a width that provides enough space for the icon and Chip
                        height: '40px', // Set a height to ensure room for the Chip
                      }}
                    >
                      <IconButton
                        onClick={handleSignatureToggle}
                        style={{ color: isSignatureChecked ? 'blue' : 'inherit' }}
                      >
                        <img
                          src={SignIcon}
                          alt="signature icon"
                          width="20"
                          style={{
                            filter: isSignatureChecked
                              ? 'invert(34%) sepia(100%) saturate(7472%) hue-rotate(180deg) brightness(95%) contrast(101%)'
                              : 'none',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}

                {editor && (
                  <EmojiComponent
                    open={emojiComponentOpen}
                    anchorElement={anchorRef.current}
                    onClose={handleEmojiComponentClose}
                    onChange={(emoji) => handleEmojiInputChange(editor, emoji)}
                  />
                )}
                {editor && (
                  <TaggerSelector
                    open={taggerComponentOpen}
                    anchorElement={anchorRef.current}
                    onClose={handleTaggerComponentClose}
                    onChange={(tag, tagId) => handleTagger(editor, tag, tagId)}
                  />
                )}
                <Divider orientation="vertical" variant="middle" flexItem />
                {isUndoable && (
                  <>
                    <Tooltip title={t('Undo')} placement="top">
                      <IconButton onClick={handleUndo} ref={anchorRef}>
                        <Undo />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Redo')} placement="top">
                      <IconButton onClick={handleRedo} ref={anchorRef}>
                        <Redo />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>
            </Stack>
          )}

          {status?.isEditDisabled && !status.isCurrentUserEditing && (
            <Stack
              direction={'row'}
              sx={{
                p: '8px',
                backgroundColor: '#f0c0c0',
                width: '100%',
                justifyContent: 'space-between',
                color: 'hsla(0, 0%, 43%, 1)',
              }}
            >
              <Typography fontWeight={600} fontSize={14} ml={0.5}>
                {t(
                  "This post is currently being edited by another user. You'll be able to make edits once it's available.",
                )}
              </Typography>
              <Lock />
            </Stack>
          )}
        </Box>
      </Box>
    </Stack>
  );
};
