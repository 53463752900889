import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { MetricByUserResponse, MetricByWeek } from '../../__generated__/graphql';
import { formatPostsCountDifference } from '../../utils';
import CardReport from '../Reports/CardReport';
import GraphicReportCompanyUserImpressionsForWeek from '../Reports/GraphicReportCompanyUserImpressionsForWeek';
import GraphicReportCompanyUserInteractionsForWeek from '../Reports/GraphicReportCompanyUserInteractionsForWeek';
import { useTranslation } from 'react-i18next';
import DatePickerRange from '../DatePicker/DatePickerRange';

interface Props {
  data: MetricByUserResponse;
  dateFilter: (dateInit: string, dateEnd: string) => void;
  dateInit: Date;
  dateEnd: Date;
  loadingButton: boolean;
  metricsByWeek: MetricByWeek[];
  differenceInDays: number;
  havelastMetricsUpdate: boolean;
}

const IndividualDashboard = ({
  data,
  dateFilter,
  dateInit,
  dateEnd,
  loadingButton,
  metricsByWeek,
  differenceInDays,
  havelastMetricsUpdate,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <DatePickerRange
        dateInit={dateInit}
        dateEnd={dateEnd}
        dateFilter={dateFilter}
        loadingButton={loadingButton}
      />

      {loadingButton ? (
        <CircularProgress
          sx={{
            display: 'block',
            margin: 'auto',
          }}
        />
      ) : (
        <>
          {!data.haveMetricsFirstRange && havelastMetricsUpdate &&  (
            <Alert
              severity="error"
              sx={{
                marginBottom: '1rem',
              }}
            >
              {t(
                'No have metrics from {{dateInit}} to {{dateEnd}}. Please select another date range.',
                {
                  dateInit: dateInit.toLocaleDateString(),
                  dateEnd: dateEnd.toLocaleDateString(),
                },
              )}
            </Alert>
          )}
          <Box
            display="flex"
            gap={2}
            alignContent="center"
            flexDirection={{ xs: 'column', md: 'row' }}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Connections')}
                // differenceInDays={differenceInDays}
                lastDays={`${t('in the last')} ${differenceInDays} ${t('days')}`}
                subtitle={false}
                comparation={formatPostsCountDifference(
                  data.followersFirstRange,
                  data.followersSecondRange,
                )}
              >
                <Typography fontSize="2rem" textAlign="center" mr="5px">
                  {data.followersFirstRange
                    ? data.followersFirstRange.toLocaleString('es-ES')
                    : 'N/A'}
                </Typography>
                <Typography fontSize="1rem" textAlign="center">
                  {' '}
                  {t('contacts + followers')}
                </Typography>
              </CardReport>
            </Box>
            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Activity')}
                lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
                subtitle
                comparation={formatPostsCountDifference(
                  data.countPostsFirstRange,
                  data.countPostsSecondRange,
                )}
                differenceInDays={differenceInDays}
              >
                <Typography fontSize="2rem" textAlign="center" mr="5px">
                  {data.countPostsFirstRange}
                </Typography>
                <Typography fontSize="1rem" textAlign="center">
                  {' '}
                  {t('posts')}
                </Typography>
              </CardReport>
            </Box>
            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Impressions')}
                lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
                subtitle
                differenceInDays={differenceInDays}
                comparation={formatPostsCountDifference(
                  data.impressionsFirstRange,
                  data.impressionsSecondRange,
                )}
              >
                <Typography fontSize="2rem" textAlign="center" mr="5px">
                  {!!data.impressionsFirstRange
                    ? data.impressionsFirstRange.toLocaleString('es-ES')
                    : 'N/A'}
                </Typography>
                <Typography fontSize="1rem" textAlign="center">
                  {' '}
                  {t('impressions')}
                </Typography>
              </CardReport>
            </Box>

            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Interactions')}
                differenceInDays={differenceInDays}
                lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
                subtitle
                comparation={formatPostsCountDifference(
                  data.interactionsFirstRange,
                  data.interactionsSecondRange,
                )}
              >
                <Typography fontSize="2rem" textAlign="center" mr="5px">
                  {!!data.interactionsFirstRange
                    ? data.interactionsFirstRange.toLocaleString('es-ES')
                    : 'N/A'}
                </Typography>
                <Typography fontSize="1rem" textAlign="center">
                  {' '}
                  {t('interactions')}
                </Typography>
              </CardReport>
            </Box>

            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Engagement')}
                differenceInDays={differenceInDays}
                lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
                subtitle
                comparation={formatPostsCountDifference(
                  (data.interactionsFirstRange / data.impressionsFirstRange) * 100,
                  (data.interactionsSecondRange / data.impressionsSecondRange) * 100,
                )}
              >
                <Box>
                  <Typography
                    marginTop="1rem"
                    fontSize="2rem"
                    textAlign="center"
                    mr="5px"
                  >
                    {data.interactionsFirstRange && data.impressionsFirstRange
                      ? `${(
                          (data.interactionsFirstRange / data.impressionsFirstRange) *
                          100
                        ).toFixed(2)}% `
                      : 'N/A'}
                  </Typography>
                  <Typography fontSize="1rem" textAlign="center">
                    {' '}
                    {t('audience reaction')}
                  </Typography>
                </Box>
              </CardReport>
            </Box>
          </Box>
        </>
      )}
      {!!metricsByWeek?.length && (
        <>
          <GraphicReportCompanyUserImpressionsForWeek
            data={metricsByWeek as MetricByWeek[]}
          />
          <GraphicReportCompanyUserInteractionsForWeek
            data={metricsByWeek as MetricByWeek[]}
          />
        </>
      )}
    </>
  );
};

export default IndividualDashboard;
