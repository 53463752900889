import {
  Box,
  Button,
  Card,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  GET_STRIPE_CHECKOUT_SESSION,
  GET_STRIPE_CUSTOMER_PORTAL_SESSION,
} from '../../graphql/mutations';
import { useContext, useState } from 'react';
import { Close, ShoppingCart } from '@mui/icons-material';
import { BillingPeriod, Language } from '../../__generated__/graphql';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from '../../context/AuthContext';
import { formatDateToLongString, capitalize } from '../../utils';

const SettingsBilling = () => {
  const { t } = useTranslation();
  const [optionSelected, setOptionSelected] = useState(BillingPeriod.Monthly);
  const [openModal, setOpenModal] = useState(false);

  const { user } = useContext(AuthContext);

  const [getStripeCheckoutSession, { loading }] = useMutation(
    GET_STRIPE_CHECKOUT_SESSION,
  );
  const [getPortalstripe] = useMutation(GET_STRIPE_CUSTOMER_PORTAL_SESSION);

  const handleClicked = async () => {
    const { data } = await getPortalstripe();
    window.open(data?.getStripeCustomerPortalSession?.sessionUrl as string, '_blank');
  };

  function calculateRemainingTrialDays(trialEndDate: Date) {
    const now = new Date();
    let trialPeriodDays = 0;

    if (trialEndDate && trialEndDate > now) {
      const remainingTime = Math.max(0, trialEndDate.getTime() - now.getTime());
      trialPeriodDays = Math.ceil(remainingTime / (24 * 60 * 60 * 1000)); // Redondear hacia arriba para contar días parciales como días completos
    } else {
      trialPeriodDays = 0; // Mostrar 0 si el período de prueba ha terminado
    }

    return trialPeriodDays;
  }

  const daysRemaining = calculateRemainingTrialDays(new Date(user?.trialEnd));

  const handleOpenCheckoutSession = () => {
    const toltID = window.tolt_referral || null;
    getStripeCheckoutSession({
      variables: {
        adjustableQuantity: false,
        billingPeriod: optionSelected,
        successUrl: `?payment=success`,
        toltID: toltID ? toltID : '',
      },
      onCompleted: ({ getStripeCheckoutSession: data }) => {
        if (!data.success) {
          alert('There was an error. Please try again later.');
        }
        if (data.success && data.sessionUrl) window.location.href = data.sessionUrl;
      },
      onError: () => {
        alert('There was an error. Please try again later.');
      },
    });
  };

  return (
    <>
      <Dialog
        open={openModal}
        keepMounted
        onClose={() => setOpenModal(false)}
        fullWidth
        sx={{
          width: '100%',
          '& .MuiDialog-paper': {
            padding: '20px',
            maxWidth: '704px',
            width: '100%',
            borderRadius: '10px',
          },
        }}
      >
        <Close
          onClick={() => setOpenModal(false)}
          sx={{
            position: 'absolute',
            right: '20px',
            top: '10px',
            cursor: 'pointer',
          }}
        />
        <Typography fontWeight={700} variant="h6" gutterBottom>
          {/* Subscribe to magnettu */}
          {t('Suscribe to magnettu')}
        </Typography>
        <Divider sx={{ marginY: '20px' }} />

        <Typography variant="subtitle1">
          {user?.language === Language.Spanish && 'Plan '}
          <b>STARTER</b> {user?.language === Language.English && ' Plan'}
        </Typography>

        <RadioGroup
          aria-label="subscription plan"
          defaultValue={BillingPeriod.Monthly}
          name="subscription-plan"
          onChange={(e) => setOptionSelected(e.target.value as BillingPeriod)}
          sx={{ marginTop: '20px' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  padding: '15px',
                  flex: 1,
                  marginBottom: '10px',
                  border: '1px solid',
                  borderColor: '#30527C80',
                  height: '147px',
                  backgroundColor:
                    optionSelected === BillingPeriod.Monthly ? '#30527C14' : 'white',
                }}
              >
                <FormControlLabel
                  value={BillingPeriod.Monthly}
                  control={
                    <Radio
                      color="primary"
                      sx={{
                        marginTop: -1,
                      }}
                    />
                  }
                  sx={{
                    alignItems: 'flex-start',
                  }}
                  label={
                    <Box>
                      <Typography color="primary.main" fontWeight={700}>
                        {t('Mensual')}
                      </Typography>
                      <Stack
                        alignItems={'center'}
                        flexDirection={'row'}
                        gap={1}
                        marginTop={2}
                      >
                        <Typography
                          color="primary.main"
                          fontWeight={700}
                          fontSize={36}
                          // display={'inline-block'}
                        >
                          24.95€{' '}
                        </Typography>
                        <Typography
                          color="primary.main"
                          fontWeight={400}
                          fontSize={18}
                          // display={'inline-block'}
                        >
                          / {t('Mensual')}
                        </Typography>
                      </Stack>
                    </Box>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  padding: '15px',
                  flex: 1,
                  marginBottom: '10px',
                  border: '1px solid',
                  borderColor: '#30527C80',
                  height: '147px',
                  backgroundColor:
                    optionSelected === BillingPeriod.Yearly ? '#30527C14' : 'white',
                }}
              >
                <FormControlLabel
                  value={BillingPeriod.Yearly}
                  control={
                    <Radio
                      color="primary"
                      sx={{
                        marginTop: -1,
                      }}
                    />
                  }
                  sx={{
                    alignItems: 'flex-start',
                  }}
                  label={
                    <Box>
                      <Typography color="primary.main" fontWeight={700}>
                        {t('Anual')}
                      </Typography>
                      <Stack
                        alignItems={'center'}
                        flexDirection={'row'}
                        gap={1}
                        marginTop={2}
                      >
                        <Typography
                          color="primary.main"
                          fontWeight={700}
                          fontSize={36}
                          // display={'inline-block'}
                        >
                          239.40€{' '}
                        </Typography>
                        <Typography
                          color="primary.main"
                          fontWeight={400}
                          fontSize={18}
                          // display={'inline-block'}
                        >
                          / {t('Anual')}
                        </Typography>
                      </Stack>
                    </Box>
                  }
                />
              </Card>
            </Grid>
          </Grid>
        </RadioGroup>

        <Divider sx={{ marginY: '20px' }} />

        <LoadingButton
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          sx={{ width: '100%', maxWidth: '365px', marginLeft: 'auto' }}
          onClick={handleOpenCheckoutSession}
          loading={loading}
        >
          <ShoppingCart
            sx={{
              marginRight: '10px',
            }}
          />
          {t('Hacer checkout')}
        </LoadingButton>
      </Dialog>
      <Card
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '40px',
          boxShadow: 'none',
        }}
      >
        {/* <CardHeader title={t("Subscription")} /> */}
        <Typography fontWeight={700} fontSize={27} color="primary.main">
          Starter
        </Typography>
        <Typography fontSize={15} marginTop={2} color="#00000099">
          {/* Turn your personal brand into your main sales channel. Start sharing content
          while continuing to run your business. */}
          {t('Turn your personal brand')}
        </Typography>

        {!!daysRemaining && (
          <>
            <Typography color="#00000099" fontSize={15} marginTop={2}>
              {t('Trial')} -
            </Typography>
            <Typography>
              {daysRemaining} {daysRemaining === 1 ? t('day') : t('days')}{' '}
              {t('Left in your trial')}
            </Typography>
          </>
        )}
        {user?.paymentDetails && !user?.isCanceled && (
          <>
            <Typography color="#00000099" fontSize={15} marginTop={2}>
              {t('Next payment')} -
            </Typography>

            <Stack
              flexDirection={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent="space-between"
              alignItems={{
                xs: 'flex-start',
                sm: 'center',
              }}
            >
              <Box>
                <Typography>
                  {formatDateToLongString(
                    new Date(user?.paymentDetails?.currentPeriodEnd),
                    user?.language === Language.Spanish ? 'es' : 'en',
                  )}
                </Typography>
                <Typography fontSize={14} fontWeight={700}>
                  {capitalize(user?.paymentDetails?.card?.brand as string)} ****{' '}
                  {user?.paymentDetails?.card?.last4}
                </Typography>
              </Box>

              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Typography color="#FF007A" fontWeight={700}>
                  {t('Total import')}:
                </Typography>

                <Typography color="#222222" fontWeight={600} fontSize={25}>
                  {user?.paymentDetails?.amountToCharge}€
                </Typography>
              </Stack>
            </Stack>
          </>
        )}

        {user?.isCanceled && (
          <>
            <Typography color="#00000099" fontSize={15} marginTop={2}>
              Your subscription has been canceled.
            </Typography>
            <Typography>
              Your subscription will end on{' '}
              {formatDateToLongString(
                new Date(user?.paymentDetails?.currentPeriodEnd),
                user?.language === Language.Spanish ? 'es' : 'en',
              )}
            </Typography>
          </>
        )}

        {(!user?.paymentDetails || user?.isCanceled) && (
          <>
            <Divider sx={{ marginY: '20px' }} />
            <Button
              variant="contained"
              sx={{
                width: '115px',
                // marginTop:2
              }}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <img
                src="/images/icons/suscribe.svg"
                style={{ width: '15px', height: '15px', marginRight: '5px' }}
                alt="share"
              />
              {t('Suscribe')}
            </Button>
          </>
        )}
      </Card>
      {user?.paymentDetails && (
        <Card
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '40px',
            boxShadow: 'none',
            marginTop: 2,
          }}
        >
          <Typography fontWeight={700} fontSize={27} color="primary.main">
            {t('Manage subscription')}
          </Typography>
          <Divider sx={{ marginY: '20px' }} />
          <Button
            variant="contained"
            sx={{
              width: '236px',
              // marginTop:2
            }}
            onClick={() => {
              // setOpenModal(true);
              handleClicked();
            }}
          >
            {t('Gestionar')}
          </Button>
        </Card>
      )}
    </>
  );
};

export default SettingsBilling;
