import { useMutation, useQuery } from '@apollo/client';
import { Favorite, TipsAndUpdates } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  OrderByDirection,
  OrderByField,
  Post,
  PostLifecycleState,
  UserPlan,
  UserRole,
} from '../../__generated__/graphql';
import BrandSettingsModal from '../../components/BrandComponents/BrandSettingsModal';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import Header from '../../components/Layout/Header';
import MasonryComponent from '../../components/Masonry/MasonryComponent';
import MasonryContentBoard from '../../components/Masonry/MasonryContentBoard';
import PostEditorModal from '../../components/PostComponents/PostEditorModal';
import PostSkeleton from '../../components/PostComponents/PostSkeleton';
import Tabs from '../../components/Tabs/Tabs';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostProvider } from '../../context/PostContext';
import { PostsFilterContext } from '../../context/PostsFilterContext';
import { WebSocketProvider } from '../../context/WebSocketContext';
import { HARD_DELETE_POSTS } from '../../graphql/mutations';
import { GET_CURRENT_BRAND_POSTS } from '../../graphql/queries';
import { ContentBoardTabs, getLifecycleStatesFromTab, OrderBy } from '../../types';
import { useTranslation } from 'react-i18next';
//import QuickFilter from '../SelectOrder/SelectOrderPost';
import SelectOrderPost from '../SelectOrder/SelectOrderPost';
import { BrandContext } from '../../context/BrandContext';
import { handleURLParameters } from '../../utils';

enum PostFilterOptions {
  MyPost = 'My Post',
  ForYou = 'For You',
  Advocacy = 'Advocacy',
  Blog = 'Blog',
  Corporate = 'Corporate',
}

const LIMIT = 24;

const ContentBoard = () => {
  const { user, setUserHavePost } = useContext(AuthContext);
  const { isMobile } = useContext(DeviceContext);
  const { setLoadingUpdateBrandAccount } = useContext(BrandContext);

  const { state } = useLocation();

  const [openedPostId, setOpenedPost] = useState<string | null>(state?.postId || null);
  const [openNewPost, setOpenNewPost] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<PostFilterOptions[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasPostsApproved, setHasPostsApproved] = useState<boolean>(false);

  const [page, setPage] = useState(0);
  const [posts, setPosts] = useState<Post[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { filter, setFilter, currentTabs } = useContext(PostsFilterContext);
  const [orderBy, setOrderBy] = useState<OrderBy>({
    field: OrderByField.CreatedOn,
    direction: OrderByDirection.Desc,
  });
  const [postsToDelete, setPostsToDelete] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [canFetchMore, setCanFetchMore] = useState(false);
  const [totalInbox, setTotalInbox] = useState<number>(0);
  const [totalApproved, setTotalApproved] = useState<number>(0);

  const brandSelected = user?.brandSelected;

  const { loading, error, data, refetch } = useQuery(GET_CURRENT_BRAND_POSTS, {
    variables: {
      filter,
      limit: LIMIT,
      page,
      orderBy,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTotalInbox(data.currentBrandPosts.totalInbox || 0);
      setTotalApproved(data.currentBrandPosts.totalApproved || 0);
      if (
        !currentTabs.includes(ContentBoardTabs.posted) &&
        data.currentBrandPosts.total
      ) {
        setUserHavePost(true);
      }

      if (page === 0) {
        setPosts(data.currentBrandPosts.posts || []);
      }

      if (page > 0) {
        const newPosts = data.currentBrandPosts.posts.filter(
          (newPost) => !posts.some((oldPost) => oldPost._id === newPost._id),
        );

        setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      }

      setHasPostsApproved(!!data.currentBrandPosts.havePostsApproved);
      setFetching(false);
      setCanFetchMore(data.currentBrandPosts.total / LIMIT > page + 1);
    },
  });

  const navigate = useNavigate();

  const [hardDeletePosts] = useMutation(HARD_DELETE_POSTS, {
    onCompleted: () => {
      refetch();
      setOpenModalDelete(false);
      setLoadingDelete(false);
      setPostsToDelete([]);
    },
  });

  const handleHardDeletePosts = () => {
    setLoadingDelete(true);
    hardDeletePosts({
      variables: {
        postIds: postsToDelete,
      },
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setPostsToDelete([]);
    } else {
      setPostsToDelete(posts.map((post) => post._id!));
    }
  };

  const handelOpenPost = (postId: string) => {
    setOpenedPost(postId);
  };

  const handleOpenNewPost = () => {
    setOpenNewPost(true);
  };

  const handlePostEditorClose = (closedPost?: Post) => {
    if (page === 0) refetch();
    else setPage(0);
    setOpenNewPost(false);
    setOpenedPost(null);
  };

  const toggleFilterOption = (option: PostFilterOptions) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleChangeTab = (tabs: ContentBoardTabs[]) => {
    // setPosts([]);
    setPage(0);
    setFilter({
      lifecycleStates: getLifecycleStatesFromTab(tabs),
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const postId = searchParams.get('postId') || undefined;
    const error = searchParams.get('error');

    if (postId) {
      setOpenedPost(postId);
      searchParams.delete('postId');

      const newUrl = searchParams.toString()
        ? `${window.location.pathname}?${searchParams.toString()}`
        : window.location.pathname;

      if (error !== 'true') {
        setLoadingUpdateBrandAccount(true);
      } else {
        searchParams.delete('error');
      }

      // Actualiza la URL sin el parámetro postId
      window.history.replaceState({}, '', newUrl);
    }
  }, [setLoadingUpdateBrandAccount]);

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const isScrollAtTheEnd = scrollTop + clientHeight >= scrollHeight;

    if (!loading && isScrollAtTheEnd && canFetchMore && !fetching) {
      setFetching(() => true);
      setPage(page + 1);
    }
  }, [page, loading, canFetchMore, fetching]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const { t } = useTranslation();

  const textNoPost = () => {
    if (
      currentTabs.includes(ContentBoardTabs.drafts) ||
      user?.plan?.includes(UserPlan.Starter)
    )
      return t('Looks like you havent crafted your first post yet');

    if (currentTabs.includes(ContentBoardTabs.inbox))
      return t(
        'No posts are available yet, but we are preparing some suggestions for you. Stay tuned.',
      );

    return t('No posts found for these filters. Try changing your selection.');
  };

  if (
    !user?.brand &&
    !user?.role.includes(UserRole.BrandManager) &&
    !user?.role.includes(UserRole.LimitedBrandManager)
  )
    return (
      <Stack direction={'column'} textAlign={'center'}>
        <Box
          sx={{
            borderRadius: '20px',
            padding: '20px',
            width: '100%',
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Typography fontSize={26} fontWeight={'700'}>
            {t('Welcome to Magnettu')}
          </Typography>
          <img
            src="../images/cajaBrand.png"
            alt="onboarding"
            style={{ width: '85px', height: '85px' }}
          />
          <Typography
            sx={{
              // width: '50%',
              textAlign: 'center',
              marginTop: '10px',
            }}
            width={{ xs: '100%', sm: '100%', md: '50%' }}
          >
            {t(
              'It’s time to set up your brand. By establishing your own brand and entering the necessary details, you’ll streamline your workflow and leverage our tools to their fullest potential. Please create your brand to begin optimizing your operations.',
            )}
          </Typography>

          <Button variant="contained" onClick={() => setOpenModal(true)}>
            {t('Create your brand')}
          </Button>
        </Box>

        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          fullScreen={isMobile}
          PaperProps={{
            sx: {
              width: '800px',
              maxWidth: '800px !important',
            },
          }}
        >
          <BrandSettingsModal onClose={() => setOpenModal(false)} />
        </Dialog>
      </Stack>
    );

  const textHeader = () => {
    switch (currentTabs[0]) {
      case ContentBoardTabs.posted:
        return t('Here you will find all the content that has been posted on LinkedIn.');
      case ContentBoardTabs.drafts:
        return t(
          'Your ideation section. You can create drafts for yourself or your team and assign them a tentative posting date. Keep in mind that the draft content will not be posted unless you schedule it.',
        );
      case ContentBoardTabs.inbox:
        return t(
          'Here you will receive content that has been created for you, whether it is created by Magnettu or someone else on your team.',
        );
      case ContentBoardTabs.scheduled:
        return t(
          'Here you will see all the content that has been scheduled and will be posted on LinkedIn on the selected dates.',
        );
      case ContentBoardTabs.approved:
        return t(
          'This is where, as a content editor or manager, you’ll see the content that has been approved by your teams or clients.',
        );
      case ContentBoardTabs.discarded:
        return t('Here you will see all the content that has been dismissed.');
      default:
        return t('We have curated content just for you.');
    }
  };

  return (
    <Box width={'100%'} p={isMobile ? '15px 25px' : '40px 25px'}>
      <Stack
        data-testid="content-board"
        direction="column"
        rowGap={2}
        maxWidth={'1800px'}
        alignSelf={'center'}
        marginX={'auto'}
      >
        <Header text={textHeader()} />
        <Stack
          direction={{ md: 'row', sm: 'column-reverse' }}
          justifyContent={'space-between'}
          width={'100%'}
          alignItems={{ sm: 'flex-start', md: 'center' }}
          gap={{ sm: 1, md: 0 }}
        >
          <Tabs
            selectedTabs={currentTabs}
            setSelectedTabs={handleChangeTab}
            showTabApproved={hasPostsApproved}
            showTabInbox={!user?.plan?.includes(UserPlan.Starter)}
            lengthInbox={totalInbox}
            lengthApproved={totalApproved}
          />

          {!isMobile && (
            <Stack flexDirection={{ sm: 'row-reverse', md: 'row' }} gap={0.5}>
              <Button
                variant="outlined"
                onClick={() => {
                  setFilter({ ...filter, favorite: !filter.favorite });
                }}
                sx={{
                  backgroundColor: filter.favorite ? '#E7EBF0' : '#FFF',
                }}
              >
                <Favorite />
              </Button>

              <Dialog
                id="simple-menu"
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  sx: {
                    overflow: 'visible',
                    width: 'auto',
                    minWidth: '300px',
                    boxShadow: '0px 11px 14px 0px rgba(0, 0, 0, 0.13)',
                    borderRadius: '5px',
                    padding: '1rem',
                  },
                }}
              >
                <Stack flexDirection="row">
                  <Box>
                    <Typography
                      fontWeight="bold"
                      sx={{
                        paddingLeft: '20px',
                        color: '#E7EBF0 ',
                        marginY: '0.5rem',
                      }}
                    >
                      {t('Content filter')}
                    </Typography>
                    {Object.values(PostFilterOptions).map((filter, i) => (
                      <MenuItem key={i} onClick={() => toggleFilterOption(filter)}>
                        <Checkbox
                          sx={{ marginRight: '5px' }}
                          checked={selectedOptions.includes(filter)}
                        />
                        <Typography fontWeight="bold">{t(filter)}</Typography>
                      </MenuItem>

                      // )
                    ))}
                  </Box>
                </Stack>
                <Box
                  flexDirection="row"
                  display="flex"
                  justifyContent="flex-end"
                  marginTop="1rem"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAnchorEl(null)}
                    data-testid="new-advocacy-post-button"
                    sx={{
                      marginLeft: '5px',
                    }}
                  >
                    <Typography fontWeight="bold">{t('Done')}</Typography>
                  </Button>
                </Box>
              </Dialog>
              <Stack flexDirection="column">
                {user.plan?.includes(UserPlan.Starter) && (
                  <Typography
                    sx={{
                      color: '#FF007A',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginTop: '5px',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      navigate('/settings');
                    }}
                  >
                    <TipsAndUpdates
                      sx={{
                        color: '#FF007A',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginTop: '10px',
                        cursor: 'pointer',
                        marginRight: '5px',
                      }}
                    />
                    {t('Pro tip: Set up your content settings here')}
                  </Typography>
                )}
              </Stack>

              {user.role?.includes(UserRole.Creator) && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenNewPost}
                  data-testid="new-advocacy-post-button"
                  disabled={!!!user?.isActive}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  <Typography fontWeight="bold">{t('Create Post')}</Typography>
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        {isMobile && (
          <>
            {user.plan?.includes(UserPlan.Starter) && (
              <Typography
                sx={{
                  color: '#FF007A',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  // marginTop: '5px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  navigate('/settings');
                }}
              >
                <TipsAndUpdates
                  sx={{
                    color: '#FF007A',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginTop: '10px',
                    cursor: 'pointer',
                    marginRight: '5px',
                  }}
                />
                {t('Pro tip: Set up your content settings here')}
              </Typography>
            )}
          </>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <SelectOrderPost
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            //fromLinkedIn={fromLinkedIn}
            //setFromLinkedIn={setFromLinkedIn}
          />
        </Stack>

        {loading ? (
          posts.length ? (
            <>
              <MasonryContentBoard
                posts={posts || []}
                postsToDelete={postsToDelete}
                setPostsToDelete={setPostsToDelete}
              />
            </>
          ) : (
            <MasonryComponent>
              {[...Array(10)].map((_, i) => (
                <PostSkeleton key={i} />
              ))}
            </MasonryComponent>
          )
        ) : error ? (
          <Typography>{t('There was an unexpected error. Please try again.')}</Typography>
        ) : posts.length ? (
          <>
            {filter.lifecycleStates?.includes(PostLifecycleState.Discarded) && (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll && postsToDelete.length === posts.length}
                    />
                  }
                  label={t('Select all')}
                  onChange={handleSelectAll}
                />
                <Button
                  onClick={() => {
                    setOpenModalDelete(true);
                  }}
                  disabled={!postsToDelete.length}
                  variant="contained"
                  sx={{
                    backgroundColor: '#D32F2F',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#D32F2F',
                    },
                  }}
                >
                  {t('Delete')} (
                  {selectAll ? data?.currentBrandPosts.total : postsToDelete.length})
                </Button>
              </Stack>
            )}
            <MasonryContentBoard
              posts={posts}
              onPostOpen={(postId) => handelOpenPost(postId)}
              setPostsToDelete={setPostsToDelete}
              postsToDelete={postsToDelete}
            />
          </>
        ) : (
          <Typography>{textNoPost()}</Typography>
        )}
      </Stack>
      {(!!openedPostId || openNewPost) && (
        <WebSocketProvider postId={openedPostId}>
          <PostProvider
            postId={openedPostId}
            newPostOptions={{
              brandId: brandSelected?._id,
              brand: brandSelected,
            }}
          >
            <PostEditorModal onClose={handlePostEditorClose} />
          </PostProvider>
        </WebSocketProvider>
      )}
      <ConfirmationDialog
        title={t('Delete posts')}
        content={
          <>
            <Divider
              sx={{
                marginBottom: '10px',
              }}
            />
            {t(
              'Deleting this post will make it permanently unavailable. This action cannot be undone, and all associated posts will be lost forever. Do you wish to proceed?',
            )}
            <Divider
              sx={{
                marginTop: '10px',
              }}
            />
          </>
        }
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onConfirm={handleHardDeletePosts}
        okText={t('Delete')}
        deleteButton
        loading={loadingDelete}
      />
    </Box>
  );
};

export default ContentBoard;
