import { Create, Delete, LinkedIn, OpenInNew, Refresh } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormHelperText,
  IconButton,
  Link,
  List,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Brand, TypeBrand } from '../../__generated__/graphql';
import { BrandContext } from '../../context/BrandContext';
import { formatDate } from '../../utils';
import Avatar from '../Avatar/Avatar';
import { BrandInput } from '../BrandComponents/BrandData';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

interface Props {
  brandSelected: Brand | null | undefined;
  errors: FieldErrors<BrandInput>;
  watchName: string;
  inputStyle: any;
  control: Control<BrandInput, any>;
  isBrandPage: boolean;
}

const SocialMediaBrand = ({
  brandSelected,
  control,
  errors,
  inputStyle,
  watchName,
  isBrandPage,
}: Props) => {
  const {
    handleRenewAccess,
    handleRemoveSocialMediaAccount,
    handleDeleteBrand,
    handleConnectNewAccount,
    loading,
  } = useContext(BrandContext);

  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (watchName === '') return;
    if (event.key === 'Enter') {
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const ButtonDeleteBrand = () => {
    return (
      <Button
        startIcon={
          <Delete
            sx={{
              color: '#D32F2F',
            }}
          />
        }
        variant="outlined"
        sx={{
          color: '#D32F2F',
          fontWeight: 700,
          borderRadius: '10px',
          borderColor: '#D32F2F',
        }}
        onClick={() => setOpenModalDelete(true)}
      >
        {t('Delete brand')}
      </Button>
    );
  };

  const LinkConnectionPage = ({ renew = false }: { renew?: boolean }) => (
    <Stack direction="row" alignItems="center" gap={1}>
      <Link
        underline="always"
        href={`/connect-linkedin/${brandSelected?._id}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ textDecoration: 'underline', color: 'primary.main', fontSize: 14 }}
      >
        {t(
          renew
            ? 'Ask someone else to renew the connection for you'
            : 'Ask someone else to connect it for you',
        )}
      </Link>
      <OpenInNew sx={{ color: 'primary.main', height: '15px', width: '15px' }} />
    </Stack>
  );

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: '100%' }}
    >
      {isBrandPage && (
        <Stack
          flexDirection="row"
          gap={2}
          marginBottom={1}
          alignContent={'center'}
          alignItems={'flex-start'}
        >
          <Avatar
            avatar={brandSelected?.account?.avatar || undefined}
            size={60}
            name={brandSelected?.name || ''}
            backgroundColor={brandSelected?.backgroundColor || ''}
          />

          <Stack gap={1}>
            {isEditing ? (
              <Controller
                name="name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    required
                    value={value}
                    onChange={(value) => {
                      // if (value.target.value === '') return;
                      onChange(value.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    sx={{ ...inputStyle, width: '200px' }}
                    autoFocus
                    variant="standard"
                    error={!!errors.name}
                  />
                )}
              />
            ) : (
              <Stack flexDirection="row" gap={2}>
                <Typography fontWeight={700} marginLeft={1}>
                  {watchName}
                </Typography>
                <Create
                  onClick={toggleEditMode}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
                <FormHelperText error={!!errors.name}>
                  {errors.name?.message}
                </FormHelperText>
              </Stack>
            )}

            {brandSelected?.account && brandSelected?.account?.type && (
              <List>
                <Stack
                  flexDirection="column"
                  justifyContent="space-between"
                  mx={1}
                  gap={1}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                  >
                    <Stack>
                      <Stack
                        flexDirection="row"
                        sx={{
                          color: '#0A66C2',
                          alignItems: 'center',
                        }}
                      >
                        <LinkedIn height="15px" width="15px" />
                        <Typography fontWeight={700} fontSize="13px">
                          {brandSelected!.account?.name}
                        </Typography>
                      </Stack>

                      <ListItemText>
                        <Typography variant="subtitle2">{`${t(
                          'Access expires on',
                        )} ${formatDate(
                          new Date(brandSelected!.account?.exp),
                        )}`}</Typography>
                      </ListItemText>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Button
                        startIcon={<Refresh />}
                        variant="outlined"
                        onClick={() =>
                          handleRenewAccess(
                            brandSelected?.account?.type!,
                            window.location.pathname,
                          )
                        }
                      >
                        {t('Renew access')}
                      </Button>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveSocialMediaAccount(brandSelected!._id)}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </Stack>
                  {brandSelected?._id && <LinkConnectionPage renew={true} />}
                </Stack>
              </List>
            )}

            {!brandSelected?.account?.type && (
              <Stack flexDirection="column" justifyContent="space-between" gap={1}>
                <Button
                  onClick={() =>
                    handleConnectNewAccount(
                      brandSelected!.typeBrand as TypeBrand,
                      window.location.pathname,
                    )
                  }
                  variant="contained"
                  sx={{ maxWidth: '300px' }}
                  startIcon={<LinkedIn />}
                >
                  Connect linkedin account
                </Button>
                {brandSelected?._id && <LinkConnectionPage />}
              </Stack>
            )}
          </Stack>
        </Stack>
      )}

      {isBrandPage && <ButtonDeleteBrand />}

      {!isBrandPage && (
        <Stack flexDirection="row" gap={2}>
          <Avatar
            avatar={
              !!brandSelected?.account?.avatar
                ? (brandSelected?.account?.avatar as string)
                : undefined
            }
            size={60}
            name={brandSelected?.name || ''}
            backgroundColor={brandSelected?.backgroundColor || ''}
          />
          <Stack flexDirection="column" gap={1}>
            {isEditing ? (
              <Controller
                name="name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    required
                    value={value}
                    onChange={(value) => {
                      onChange(value.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    sx={{ ...inputStyle, width: '200px' }}
                    autoFocus
                    variant="standard"
                    error={!!errors.name}
                  />
                )}
              />
            ) : (
              <Stack flexDirection="row" gap={2}>
                <Typography fontWeight={700} marginLeft={1}>
                  {watchName}
                </Typography>
                <Create
                  onClick={toggleEditMode}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
                <FormHelperText error={!!errors.name}>
                  {errors.name?.message}
                </FormHelperText>
              </Stack>
            )}

            {brandSelected?.account && brandSelected.account?.name && (
              <List>
                <Stack flexDirection="row" justifyContent="space-between">
                  <Stack flexDirection="row" justifyContent="center" alignItems="center">
                    <Stack marginX="10px">
                      <Stack
                        flexDirection="row"
                        sx={{
                          color: '#0A66C2',
                          alignItems: 'center',
                        }}
                      >
                        <LinkedIn height="15px" width="15px" />
                        <Typography fontWeight={700} fontSize="13px">
                          {brandSelected!.account?.name}
                        </Typography>
                      </Stack>

                      <ListItemText>
                        <Typography variant="subtitle2">{`${t(
                          'Access expires on',
                        )} ${formatDate(
                          new Date(brandSelected!.account?.exp),
                        )}`}</Typography>
                      </ListItemText>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Button
                        startIcon={<Refresh />}
                        variant="outlined"
                        onClick={() =>
                          handleRenewAccess(
                            brandSelected!.account!.type!,
                            window.location.pathname,
                          )
                        }
                      >
                        {t('Renew access')}
                      </Button>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveSocialMediaAccount(brandSelected!._id)}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>
              </List>
            )}

            {!brandSelected?.account && (
              <Stack flexDirection="row" justifyContent="space-between" marginTop={1}>
                <Button
                  onClick={() =>
                    handleConnectNewAccount(
                      brandSelected!.typeBrand as TypeBrand,
                      window.location.pathname,
                    )
                  }
                  variant="contained"
                  sx={{ maxWidth: '300px' }}
                  startIcon={<LinkedIn />}
                >
                  Connect linkedin account
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}

      <ConfirmationDialog
        title={t('Are you sure you want to delete this brand?')}
        content={
          <>
            <Divider
              sx={{
                marginBottom: '10px',
              }}
            />
            {t('Deleting this brand will make all posts linked to it permanently unavailable')}
            <Divider
              sx={{
                marginTop: '10px',
              }}
            />
          </>
        }
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onConfirm={() => handleDeleteBrand(brandSelected!._id)}
        okText={t('Delete')}
        deleteButton
        loading={loading}
      />
    </Stack>
  );
};

export default SocialMediaBrand;
