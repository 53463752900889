import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { BrandMetricsWithShell } from '../../__generated__/graphql';
import { FC, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface Data {
  userId: string;
  name: string;
  plan: string;
  postsTotal: number;
  interactions: number;
  magnetsTotal: number;
  shares: number;
  views: number;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key,
): (a: Data, b: Data) => number {
  return (a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    const isNumericValue = typeof aValue === 'number' && typeof bValue === 'number';

    if (aValue === '-' && bValue === '-') {
      return 0;
    }

    if (aValue === '-') {
      return 1;
    }

    if (bValue === '-') {
      return -1;
    }

    if (isNumericValue) {
      return order === 'desc' ? bValue - aValue : aValue - bValue;
    }

    // @ts-ignore
    return order === 'desc' ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
  };
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const hasNA = (value: any) => value === '-';

  const sortedArray = [...array];
  sortedArray.sort((a, b) => {
    const aIsNA = hasNA(a);
    const bIsNA = hasNA(b);

    if (aIsNA && bIsNA) {
      return 0;
    }

    if (aIsNA) {
      return 1;
    }

    if (bIsNA) {
      return -1;
    }

    return comparator(a, b);
  });

  return sortedArray;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  type: 'Activity' | 'Performance';
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('Name'),
    },
    {
      id: 'postsTotal',
      numeric: true,
      disablePadding: false,
      label: t('Posts (Total)'),
    },
    ...(props.type === 'Activity'
      ? ([
          {
            id: 'magnetsTotal',
            numeric: true,
            disablePadding: false,
            label: t('Magnets (Total)'),
          },
        ] as HeadCell[]) // Forzar que TypeScript reconozca este objeto como HeadCell[]
      : ([
          {
            id: 'views',
            numeric: true,
            disablePadding: false,
            label: t('Views'),
          },
          {
            id: 'interactions',
            numeric: true,
            disablePadding: false,
            label: t('Interactions (Total)'),
          },
          {
            id: 'shares',
            numeric: true,
            disablePadding: false,
            label: t('Shares *'),
          },
        ] as HeadCell[])),
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              backgroundColor: '#6E7794',
              borderRadius:
                index === 0
                  ? '10px 0 0 0'
                  : index === headCells.length - 1
                  ? '0 10px 0 0'
                  : '0',
                  color: 'white',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{
                color: orderBy === headCell.id ? '#f5e2ae !important' : 'white', // Cambia el color del texto cuando está activo
                '& .MuiTableSortLabel-icon': {
                  color:
                    orderBy === headCell.id ? 'white !important' : 'white !important', // Cambia el color de las flechas cuando está activo
                },
                '&:hover': {
                  color: '#f5f0e2', // Cambia el color del texto y las flechas al hacer hover
                },
              }}
            >
              {headCell.label.includes('(') ? (
                <>
                  {headCell.label.split('(')[0]}
                  <br />({headCell.label.split('(')[1]}
                </>
              ) : (
                headCell.label
              )}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface AnalitysTableProps {
  data: BrandMetricsWithShell[] | [];
  type: 'Activity' | 'Performance';
}
const AnalitysTable: FC<AnalitysTableProps> = ({ data, type }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();

  const rows: BrandMetricsWithShell[] | any = data.map((item: BrandMetricsWithShell) => {
    const {
      brandId,
      brandName,
      postsTotal,
      interactions,
      magnetsTotal,
      useExtension,
      shares,
      views,
    } = item;

    return {
      brandId,
      name: brandName,
      postsTotal,
      interactions: useExtension ? interactions : '-',
      magnetsTotal,
      views: useExtension ? views : '-',
      shares: useExtension ? shares : '-',
    };
  });

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('postsTotal');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%', marginTop: '2rem' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer sx={{
            borderRadius: '10px',
          }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              type={type}
            />
            <TableBody>
              {type === 'Activity' && (
                <TableRow hover>
                  <TableCell component="th" scope="row" align="center">
                    All
                  </TableCell>
                  <TableCell align="center">
                    {rows.reduce(
                      (acc: number, row: { postsTotal: number }) => acc + row.postsTotal,
                      0,
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {rows.reduce(
                      (acc: number, row: { magnetsTotal: number }) =>
                        acc + row.magnetsTotal,
                      0,
                    )}
                  </TableCell>
                </TableRow>
              )}
              {visibleRows.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index} >
                    <TableCell component="th" scope="row" align="center">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.postsTotal}</TableCell>
                    {type === 'Performance' && (
                      <>
                        <TableCell align="center">{row.views}</TableCell>
                        <TableCell align="center">{row.interactions}</TableCell>
                        <TableCell align="center">{row.shares}</TableCell>
                      </>
                    )}
                    {type === 'Activity' && (
                      <TableCell align="center">{row.magnetsTotal}</TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {type === 'Performance' && (
        <Typography color="textSecondary" variant="body2">
          *{t('Totals do not consider brands that are not getting data from LinkedIn')}
        </Typography>
      )}
    </Box>
  );
};

export default AnalitysTable;
