import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Stack,
  Typography,
  CircularProgress,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UsersTable from '../../components/Tables/UsersTable';
import { DeviceContext } from '../../context/DeviceContext';
import { GET_SHELLS, GET_USERS } from '../../graphql/queries';
import { getFullName } from '../../utils';
import { useTranslation } from 'react-i18next';

const inputStyle = { minWidth: '250px', height: '10px' };

const UsersPanel = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>();
  const [shellId, setShellId] = useState<string>();

  const { data: usersData, loading } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
  });

  const { data: shellsData } = useQuery(GET_SHELLS, {
    fetchPolicy: 'network-only',
  });

  const users = usersData?.users || [];
  const shells = shellsData?.shells || [];

  const filteredUsers = users.filter((user) => {
    let result = true;
    if (userId) result = result && user._id === userId;
    if (shellId) result = result && user.shellId === shellId;

    return result;
  });

  return (
    <>
      <Stack
        direction="column"
        p="40px 30px"
        spacing={3}
        alignItems={isMobile ? 'center' : 'start'}
      >
        <Stack direction="row" width="100%" textAlign="center">
          <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
            {t('Users')}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" flex={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('new')}
              data-testid="new-user-button"
            >
              <AddIcon sx={{ marginRight: '10px' }} />
              <Typography fontWeight="bold">{t('New User')}</Typography>
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ width: '100%', justifyContent: 'flex-end' }}
        >
          <Autocomplete
            options={users.map((user) => ({
              key: user._id, // Ensure keys are unique by using _id
              id: user._id,
              label: getFullName(user), // Combine name and email
              email: user.email,
            }))}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="body1">
                  {option.label.split(' (')[0]} {/* Display only the name */}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {option.email}
                </Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Search user')}
                variant="outlined"
                size="small"
                sx={inputStyle}
              />
            )}
            onChange={(_, value) => setUserId(value?.id || '')} // Change to use id for filter to ensure uniqueness
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.email.toLowerCase().includes(inputValue.toLowerCase()),
              )
            }
            data-testid="search-name-input"
          />
          <Autocomplete
            options={shells.map((shell) => ({
              key: shell._id, // Use _id as key
              id: shell._id,
              label: shell.name || <em>{t('None')}</em>,
            }))}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Client')}
                variant="outlined"
                size="small"
                sx={inputStyle}
              />
            )}
            onChange={(_, value) => setShellId(value?.id || '')}
            data-testid="search-company-select"
          />
        </Stack>
        {loading && <CircularProgress />}
        {!loading && <UsersTable users={filteredUsers} />}
      </Stack>
    </>
  );
};

export default UsersPanel;
