import { useNavigate, useParams } from 'react-router-dom';
import PostEditorModal from '../../components/PostComponents/PostEditorModal';
import { AuthContext } from '../../context/AuthContext';
import { useContext, useEffect } from 'react';
import { WebSocketProvider } from '../../context/WebSocketContext';
import { PostProvider } from '../../context/PostContext';
import { BrandContext } from '../../context/BrandContext';
import LinkedinPageSelector from '../../components/SocialMediaAccounts.tsx/LinkedinPageSelector';
import { SnackbarContext } from '../../context/SnackbarContext';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import { AlertColor } from '@mui/material';

const PostEditor = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { postId: postParam } = useParams();
  const {
    handleSaveLinkedinCompanyCredentials,
    setLinkedinPageSelectorOpen,
    pages,
    openModalCompany,
    setOpenModalCompany,
    setLoadingUpdateBrandAccount,
    } = useContext(BrandContext);

  // const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const postId = searchParams.get('postId') || undefined;
    const error = searchParams.get('error');

    if (postId) {
      // Elimina solo el parámetro postId
      searchParams.delete('postId');
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;

      if (error !== 'true') {
        setLoadingUpdateBrandAccount(true);
      } else {
        searchParams.delete('error');
      }

      window.history.replaceState({}, '', newUrl);
    }
  }, [setLoadingUpdateBrandAccount]);

  const snackbar = useContext(SnackbarContext);

  const snackbarTypes = [
    { severity: 'success', message: snackbar.successMessage },
    { severity: 'error', message: snackbar.errorMessage },
    { severity: 'warning', message: snackbar.warningMessage },
  ];

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    snackbar.setSuccessMessage(null);
    snackbar.setErrorMessage(null);
    snackbar.setWarningMessage(null);
  };

  const postId = postParam || null;
  return (
    <>
      <WebSocketProvider postId={postId}>
        <PostProvider
          postId={postId}
          newPostOptions={{
            brandId: user?.brandSelectedId || user?.brandId,
            brand: user?.brandSelected || user?.brand,
          }}
          isLink
        >
          <PostEditorModal
            onClose={() => {
              navigate('/');
            }}
            isLink
          />
        </PostProvider>
      </WebSocketProvider>

      <LinkedinPageSelector
        open={openModalCompany}
        onClose={() => {
          setOpenModalCompany(false);
          setLoadingUpdateBrandAccount(false);
        }}
        onSubmit={(page) => {
          handleSaveLinkedinCompanyCredentials(page);
          setLinkedinPageSelectorOpen(null);
          setOpenModalCompany(false);
        }}
        pages={pages}
      />
      {snackbarTypes.map((snackbarType) => (
        <CustomSnackbar
          key={snackbarType.severity}
          severity={snackbarType.severity as AlertColor}
          message={snackbarType.message}
          handleClose={handleClose}
        />
      ))}
    </>
  );
};

export default PostEditor;
